import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Button,
} from "reactstrap";
import classnames from "classnames";
import InterestedSubjectTabs from "../MemberTabList/InterestedSubject/InterestedSubjectTabs";
import Address from "../MemberTabList/Address/Address";
import { useMemberDetailsInfo } from "../../../hooks/useMemberDetails";
import { useMemberDetailsContext } from "../../../shared/_helper/MemberDetails/MemberDetailsProvider";
import EducationInfo from "../MemberTabList/EducationInfo/EducationInfo";
import About from "../MemberTabList/AboutMe/About";
import { ProfilePictureRootURL } from "../../../api";
import man from "../../../assets/images/avtar/man.png";
import { IoArrowBack } from "react-icons/io5";
import StudentTransactionsTabs from "../MemberTabList/Transactions/StudentTransactionsTabs";
import { useStudentTransactionSummery } from "../../../hooks/useStudentTransactionSummery";
import TutorTransactionsTabs from "../MemberTabList/Transactions/TutorTransactionsTabs";
import TutorPayoutTabs from "../MemberTabList/Transactions/TutorPayoutTabs";

const MemberDetailsContain = (props) => {
  const params = useParams();
  const memberId = params.id;
  const { data, refetch, isLoading } = useMemberDetailsInfo(memberId);
  const { data:transactionData, refetch:transactionRefetch, isLoading:transactionLoading } = useStudentTransactionSummery(memberId);
  const { memberDetails, memberDateOfBirth } = useMemberDetailsContext();
  const [activeTab, setActiveTab] = useState("1");
  const [studentSummeryList, setStudentSummeryList] = useState([]);
  const [tutorSummeryList, setTutorSummeryList] = useState([]);

  useEffect(()=>{
    console.log('trans',transactionData);
    if(transactionData && transactionData?.status===200){
      const filteredStudentTransactions=transactionData?.data?.transactions?.filter((item)=>item?.status==='class_payment'||item?.status==='cancellation_credit'||item?.status==='tuition_closed_credit');
      const filteredStudentData={
        ...transactionData?.data,
        transactions:filteredStudentTransactions
      }
      setStudentSummeryList(filteredStudentData);
      const filteredTutorTransactions=transactionData?.data?.transactions?.filter((item)=>item?.status==='pay out'||item?.status==='class_complete');
      const filteredTutorData={
        ...transactionData?.data,
        transactions:filteredTutorTransactions
      }
      setTutorSummeryList(filteredTutorData);
    }
  },[transactionData]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div>
      <Row className="d-flex align-items-center">
        <Col sm="2">
          <Link to="/member-list">
            <IoArrowBack style={{ fontSize: "26px" }} />
          </Link>
        </Col>

        <Col sm="6" className="d-flex justify-content-end">
          <Nav
            pills
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggle("1");
                }}
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  background: activeTab === "1" ? "#ff8819" : "",
                }}
              >
                About Me
              </NavLink>
            </NavItem>
            {/* <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggle("2");
            }}
            style={{ fontSize: "16px", cursor: "pointer" }}
          >
            Address
          </NavLink>
        </NavItem> */}
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "3" })}
                onClick={() => {
                  toggle("3");
                }}
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  background: activeTab === "3" ? "#ff8819" : "",
                }}
              >
                Education Info
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "4" })}
                onClick={() => {
                  toggle("4");
                }}
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  background: activeTab === "4" ? "#ff8819" : "",
                }}
              >
                Interested Subject
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "5" })}
                onClick={() => {
                  toggle("5");
                }}
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  background: activeTab === "5" ? "#ff8819" : "",
                }}
              >
                Student Transactions
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "6" })}
                onClick={() => {
                  toggle("6");
                }}
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  background: activeTab === "6" ? "#ff8819" : "",
                }}
              >
                Tutor Transactions
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "7" })}
                onClick={() => {
                  toggle("7");
                }}
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  background: activeTab === "7" ? "#ff8819" : "",
                }}
              >
                Tutor Payout
              </NavLink>
            </NavItem>

            {/* <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "5" })}
            onClick={() => {
              toggle("5");
            }}
            style={{ fontSize: "16px", cursor: "pointer" }}
          >
            Schedule
          </NavLink>
        </NavItem> */}
          </Nav>
        </Col>
        <Col
          sm="4"
          className="d-flex justify-content-end align-items-center text-end"
        >
          <h4
            style={{ fontSize: "16px", paddingRight: "8px", color: "#2a6a7a" }}
          >
            {memberDetails?.name?.firstName +
              " " +
              memberDetails?.name?.lastName}
          </h4>
          <img
            style={{ width: "40px", height: "40px", borderRadius: "50%" }}
            src={
              memberDetails?.media?.profilePicture?.imageInfo?.secure_url
                ? memberDetails?.media?.profilePicture?.imageInfo?.secure_url
                : ProfilePictureRootURL + memberDetails?.media?.picture
                ? ProfilePictureRootURL + memberDetails?.media?.picture
                : man
            }
            class="img-fluid"
            alt="User-profile"
          ></img>
        </Col>
      </Row>
      <TabContent activeTab={activeTab} className="mt-10">
        <TabPane tabId="1" style={{ minHeight: "100vh" }}>
          <Row>
            <Col sm="12">
              <About
                profileInfo={memberDetails?.media}
                userId={memberDetails?.userId}
                userName={memberDetails?.name}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col sm="12">
              <Address />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row>
            <Col sm="12">
              <EducationInfo
                educationsInfo={memberDetails?.educations}
                refetch={refetch}
                isLoading={isLoading}
                memberDateOfBirth={memberDateOfBirth}
                userId={memberDetails?.userId}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="4">
          <Row>
            <Col sm="12">
              <InterestedSubjectTabs
                memberDetails={memberDetails}
                refetch={refetch}
                isLoading={isLoading}
              />
            </Col>
          </Row>
        </TabPane>

        <TabPane tabId="5">
          <Row>
            <Col sm="12">
              <StudentTransactionsTabs
                 summeryList={studentSummeryList}
                 title="All"
                 memberType={'student'}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="6">
          <Row>
            <Col sm="12">
             <TutorTransactionsTabs
             summeryList={tutorSummeryList}
             title="All"
             memberType={'tutor'}
             />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="7">
          <Row>
            <Col sm="12">
             <TutorPayoutTabs/>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="8">
          <Row>
            <Col sm="12">
              <h4>Tab 2 Content</h4>
              <p>This is the content of tab 2.</p>
            </Col>
          </Row>
        </TabPane>
      </TabContent>

      <Link to="/member-list">
        <Button
          color="warning"
          className="text-end"
          style={{
            fontSize: "16px",
            right: "180px",
            padding: "5px 22px",
          }}
        >
          Back
        </Button>
      </Link>
    </div>
  );
};

export default MemberDetailsContain;
