import React, { Fragment, useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
    Card,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Row,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
} from "reactstrap";
// import { ProfilePictureRootURL } from "../../../api";
import man from "../../../../assets/images/profile-image/profile-image.png";
import {
    ProfilePictureRootURL,
    TutorDetailsGetApi,
    TutorPayableAmountGetApi,
    TutorPayoutDoneUpdateApi,
    TutorPayoutGetApi,
} from "../../../../api";
import { toast } from "react-toastify";

const TutorPaymentInfoContain = () => {
    const params = useParams();
    const tutorId = params.id;
    const userData = JSON.parse(localStorage.getItem("adminData"));
    const localStorageToken = userData.token;
    const [tutorDetails, setTutorDetails] = useState("");
    const [tutorPayableAmountDetails, setTutorPayableAmountDetails] =
        useState("");
    const [payableAmount, setPayableAmount] = useState("");
    const [tutorPayoutMethod, setTutorPayoutMethod] = useState("");
    const [tutorPayoutDetails, setTutorPayoutDetails] = useState("");
    const [payoutBtnDisable, setPayoutBtnDisable] = useState(true);
    const [paidAmount, setPaidAmount] = useState();

    //----------------for tutors details-------------------
    useEffect(async () => {
        try {
            const res = await axios.post(
                TutorDetailsGetApi,
                {
                    id: tutorId,
                },
                {
                    headers: {
                        authorization: `Bearer ${localStorageToken}`,
                    },
                }
            );
            if (res) {
                setTutorDetails(res.data);
            }
        } catch (error) {
            console.error(error);
            setTutorDetails("");
        }
        return () => setTutorDetails("");
    }, [tutorId, localStorageToken]);

    //--------for tutor's payable amount details---------------
    useEffect(async () => {
        try {
            const res = await axios.post(
                TutorPayableAmountGetApi,
                {
                    id: tutorId,
                },
                {
                    headers: {
                        authorization: `Bearer ${localStorageToken}`,
                    },
                }
            );
            if (res) {
                // console.log(res.data);
                setTutorPayableAmountDetails(res.data);
            }
        } catch (error) {
            console.error(error);
            setTutorPayableAmountDetails("");
        }

        return () => setTutorPayableAmountDetails("");
    }, [tutorId, localStorageToken]);

    //--------for tutor's payout details---------------
    useEffect(async () => {
        try {
            const res = await axios.post(
                TutorPayoutGetApi,
                {
                    id: tutorId,
                },
                {
                    headers: {
                        authorization: `Bearer ${localStorageToken}`,
                    },
                }
            );
            if (res) {
                console.log(res.data.info);
                setTutorPayoutMethod(res?.data?.method);
                setTutorPayoutDetails(res?.data?.info);
            }
        } catch (error) {
            console.error(error);
            setTutorPayoutMethod("");
            setTutorPayoutDetails("");
        }

        return () => {
            setTutorPayoutMethod("");
            setTutorPayoutDetails("");
        };
    }, [tutorId, localStorageToken]);

    //-------for resetting other payout methods---------
    useEffect(() => {
        if (tutorPayoutMethod) {
            if (tutorPayoutMethod.includes("others")) {
                const paymentMethod = tutorPayoutMethod.split("/")[1];
                // console.log(paymentMethod);
                setTutorPayoutMethod(paymentMethod);
            }
        }
    }, [tutorPayoutMethod]);

    //-------for resetting other payable amount---------
    useEffect(() => {
        if (tutorPayableAmountDetails) {
            setPayableAmount(tutorPayableAmountDetails?.payableAmount);
        }
    }, [tutorPayableAmountDetails]);

    //--disabling payout done button until payableAmountDetails arrives---
    useEffect(() => {
        if (tutorPayableAmountDetails && tutorPayoutDetails && tutorPayoutMethod && paidAmount) {
            if (tutorPayableAmountDetails.payableAmount > 0) {
                setPayoutBtnDisable(false);
            } else { setPayoutBtnDisable(true) };
        } else { setPayoutBtnDisable(true) };
    }, [tutorPayableAmountDetails, tutorPayoutDetails, tutorPayoutMethod]);

    //---------after manually paying out the amount--------
    const handlePay = async (e) => {
        e.preventDefault();
        if (!paidAmount || paidAmount <= 0 || Number(paidAmount) > tutorPayableAmountDetails?.payableAmount) {
            toast.error("Invalid Paid Amount");
            return;
        }
        const confirmation = prompt(
            "Type 'CONFIRM' in capital letters then click OK to confirm payout is done"
        );
        if (confirmation !== "CONFIRM") {
            toast.error("Payout not done");
            return;
        }

        try {
            const res = await axios.put(
                TutorPayoutDoneUpdateApi,
                {
                    id: tutorId,
                    payableAmount: Number(paidAmount),
                    payoutDetails: {
                        tutorPayoutMethod,
                        tutorPayoutDetails
                    }
                },
                {
                    headers: {
                        authorization: `Bearer ${localStorageToken}`,
                    },
                }
            );
            console.log(res.data);
            if (res) {
                if (res.data.acknowledged) {
                    toast.success("Payout done successfully");
                    setPayableAmount(tutorPayableAmountDetails?.payableAmount - Number(paidAmount));
                    setPayoutBtnDisable(true);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Fragment>
            <div>

                <Row className="d-flex justify-content-between">
                    <Col sm="6">
                        <div>
                            <h3 className="mb-3">Payout Information</h3>
                            <Form>
                                <FormGroup row>
                                    <Label for="exampleEmail2" sm={4}>
                                        Payout Method
                                    </Label>

                                    <Col sm={8}>
                                        <Input
                                            disabled
                                            id="exampleEmail2"
                                            name="email"
                                            placeholder="default"
                                            type="email"
                                            value={
                                                tutorPayoutMethod ? tutorPayoutMethod : "Not provided"
                                            }
                                        />
                                    </Col>
                                </FormGroup>
                                {tutorPayoutDetails?.bankName && (
                                    <FormGroup row>
                                        <Label for="exampleEmail2" sm={4}>
                                            Bank Name
                                        </Label>
                                        <Col sm={8}>
                                            <Input
                                                disabled
                                                id="exampleEmail2"
                                                name="email"
                                                type="email"
                                                value={tutorPayoutDetails?.bankName}
                                            />
                                        </Col>
                                    </FormGroup>
                                )}
                                {tutorPayoutDetails?.accountName && (
                                    <FormGroup row>
                                        <Label for="exampleEmail2" sm={4}>
                                            Account Name
                                        </Label>
                                        <Col sm={8}>
                                            <Input
                                                disabled
                                                id="exampleEmail2"
                                                name="email"
                                                type="email"
                                                value={tutorPayoutDetails?.accountName}
                                            />
                                        </Col>
                                    </FormGroup>
                                )}
                                {tutorPayoutDetails?.accountNumber && (
                                    <FormGroup row>
                                        <Label for="exampleEmail2" sm={4}>
                                            Account Number
                                        </Label>
                                        <Col sm={8}>
                                            <Input
                                                disabled
                                                id="exampleEmail2"
                                                name="email"
                                                type="email"
                                                value={tutorPayoutDetails?.accountNumber}
                                            />
                                        </Col>
                                    </FormGroup>
                                )}

                                {tutorPayoutDetails?.branchName && (
                                    <FormGroup row>
                                        <Label for="exampleEmail2" sm={4}>
                                            Branch Name
                                        </Label>
                                        <Col sm={8}>
                                            <Input
                                                disabled
                                                id="exampleEmail2"
                                                name="email"
                                                type="email"
                                                value={tutorPayoutDetails?.branchName}
                                            />
                                        </Col>
                                    </FormGroup>
                                )}
                                {tutorPayoutDetails?.routingNumber && (
                                    <FormGroup row>
                                        <Label for="exampleEmail2" sm={4}>
                                            Routing Number
                                        </Label>
                                        <Col sm={8}>
                                            <Input
                                                disabled
                                                id="exampleEmail2"
                                                name="email"
                                                type="email"
                                                value={tutorPayoutDetails?.routingNumber}
                                            />
                                        </Col>
                                    </FormGroup>
                                )}
                                {tutorPayoutDetails?.swiftCode && (
                                    <FormGroup row>
                                        <Label for="exampleEmail2" sm={4}>
                                            Swift Code
                                        </Label>
                                        <Col sm={8}>
                                            <Input
                                                disabled
                                                id="exampleEmail2"
                                                name="email"
                                                type="email"
                                                value={tutorPayoutDetails?.swiftCode}
                                            />
                                        </Col>
                                    </FormGroup>
                                )}
                                {tutorPayoutDetails?.email && (
                                    <FormGroup row>
                                        <Label for="exampleEmail2" sm={4}>
                                            Account Email
                                        </Label>
                                        <Col sm={8}>
                                            <Input
                                                disabled
                                                id="exampleEmail2"
                                                name="email"
                                                type="email"
                                                value={tutorPayoutDetails?.email}
                                            />
                                        </Col>
                                    </FormGroup>
                                )}
                                {tutorPayoutDetails?.phone && (
                                    <FormGroup row>
                                        <Label for="exampleEmail2" sm={4}>
                                            Account Phone Number
                                        </Label>
                                        <Col sm={8}>
                                            <Input
                                                disabled
                                                id="exampleEmail2"
                                                name="email"
                                                type="email"
                                                value={tutorPayoutDetails?.phone}
                                            />
                                        </Col>
                                    </FormGroup>
                                )}
                                {tutorPayoutDetails?.mobileNumber && (
                                    <FormGroup row>
                                        <Label for="exampleEmail2" sm={4}>
                                            Account Mobile Number
                                        </Label>
                                        <Col sm={8}>
                                            <Input
                                                disabled
                                                id="exampleEmail2"
                                                name="email"
                                                type="email"
                                                value={tutorPayoutDetails?.mobileNumber}
                                            />
                                        </Col>
                                    </FormGroup>
                                )}
                                {tutorPayoutDetails?.mobileNumberOrId && (
                                    <FormGroup row>
                                        <Label for="exampleEmail2" sm={4}>
                                            Account Mobile Number / {tutorPayoutMethod} ID
                                        </Label>
                                        <Col sm={8}>
                                            <Input
                                                disabled
                                                id="exampleEmail2"
                                                name="email"
                                                type="email"
                                                value={tutorPayoutDetails?.mobileNumberOrId}
                                            />
                                        </Col>
                                    </FormGroup>
                                )}
                                {tutorPayoutDetails?.referenceNumber && (
                                    <FormGroup row>
                                        <Label for="exampleEmail2" sm={4}>
                                            Reference Number
                                        </Label>
                                        <Col sm={8}>
                                            <Input
                                                disabled
                                                id="exampleEmail2"
                                                name="email"
                                                type="email"
                                                value={tutorPayoutDetails?.referenceNumber}
                                            />
                                        </Col>
                                    </FormGroup>
                                )}
                                <FormGroup row>
                                    <Label for="exampleEmail2" sm={4}>
                                        Total Payable Amount
                                    </Label>
                                    <Col sm={8}>
                                        <Input
                                            disabled
                                            id="exampleEmail2"
                                            name="email"
                                            type="email"
                                            value={payableAmount ? Number(payableAmount).toFixed(2) : "loading"}
                                        />
                                    </Col>
                                </FormGroup>

                                {/* <Link to="/tutor-list">
                                    <Button
                                        color="warning"
                                        className="text-end"
                                        style={{
                                            fontSize: "16px",
                                            position: "absolute",
                                            right: "180px",
                                            padding: "5px 22px",
                                        }}
                                    >
                                        Back
                                    </Button>
                                </Link> */}


                            </Form>
                        </div>
                    </Col>
                    <Col sm="5">
                        <h3 className="mb-3">Balance Information</h3>
                        <Form>
                            <FormGroup row>
                                <Label for="exampleEmail2" sm={4}>
                                    Payable Amount
                                </Label>

                                <Col sm={8}>
                                    <Input
                                        disabled
                                        id="exampleEmail2"
                                        name="email"
                                        placeholder="default"
                                        type="email"
                                        value={payableAmount ? Number(payableAmount).toFixed(2) : "loading"}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="paidAmount" sm={4}>
                                    Paid Amount
                                </Label>

                                <Col sm={8}>
                                    <Input
                                        id="paidAmount"
                                        name="paidAmount"
                                        placeholder="Enter paid amount..."
                                        type="text"
                                        value={paidAmount}
                                        onChange={(e) => setPaidAmount(e.target.value)}
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                        <div>
                            <Button
                                disabled={payoutBtnDisable}
                                onClick={handlePay}
                                color="primary"
                                className="text-end"
                                style={{
                                    fontSize: "16px",
                                    position: "absolute",
                                    right: "15px",
                                    padding: "5px 34px",
                                }}
                            >
                                Payout Done
                            </Button>
                        </div>
                    </Col>

                </Row>
            </div>
        </Fragment>
    );
};

export default TutorPaymentInfoContain;
