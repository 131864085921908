import axios from "axios";
import {
  AllSubjectFetchApi,
  UpdateTutorInterestedToTech,
  UpdateStudentInterestedToLearn,
} from "../../api";
import { toast } from "react-toastify";

export const getAllSubjects = async () => {
  const userData = JSON?.parse(localStorage.getItem("adminData"));
  const localStorageToken = userData?.token;
  if (!localStorageToken) {
    toast.warn("Invalid Token!", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
    return;
  }
  try {
    const res = await axios.get(AllSubjectFetchApi, {
      headers: {
        authorization: `Bearer ${localStorageToken}`,
      },
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const editInterestPricing = async ({
  tuitionTitle,
  subjects,
  specialization,
  classes,
  // currency,
  amount,
  isNegotiable,
  tutionTypes,
  userId,
  tuitionDetails,
  interestedToTechId,
}) => {
  var data = {
    tuitionTitle: tuitionTitle,
    subjects: subjects,
    classes: {
      id: classes.value,
      name: classes.label,
    },
    specializeSubjects: specialization,
    tutionTypes: tutionTypes,
    amount: {
      initialPrice: parseFloat(amount),
      confirmedPrice: 0,
      isNegotiable: isNegotiable,
    },
    tuitionDetails: tuitionDetails,
  };

  const userData = JSON?.parse(localStorage.getItem("adminData"));
  const localStorageToken = userData?.token;
  if (!localStorageToken) {
    toast.error("Invalid token");
    return;
  }
  const res = await axios.put(
    `${UpdateTutorInterestedToTech}${userId}/${interestedToTechId}`,
    data,
    {
      headers: {
        authorization: `Bearer ${localStorageToken}`,
      },
    }
  );

  return res;
};

export const editInterestedToLearnInfo = async ({
  subjects,
  specializeSubjects,
  classes,

  amount,
  isNegotiable,
  tutionTypes,
  userId,

  interestToLearnId,
}) => {
  var data = {
    subjects: subjects,
    classes: {
      id: classes.value,
      name: classes.label,
    },
    specializeSubjects: specializeSubjects,

    tutionTypes: tutionTypes,
    amount: {
      initialPrice: parseFloat(amount),
      confirmedPrice: 0,
      isNegotiable: isNegotiable,
    },
  };

  const userData = JSON?.parse(localStorage.getItem("adminData"));
  const localStorageToken = userData?.token;
  if (!localStorageToken) {
    toast.error("Invalid token");
    return;
  }
  const res = await axios.put(
    `${UpdateStudentInterestedToLearn}${userId}/${interestToLearnId}`,
    data,
    {
      headers: {
        authorization: `Bearer ${localStorageToken}`,
      },
    }
  );

  return res;
};
