import TutorList from "../../pages/TutorList";
import TutorPaymentInfo from "../../pages/TutorPayableInfo";
import Dashboard from "../../pages/dashboard/Dashboard";
import CreateVoucherContain from "../../components/CreateVoucher";
import VoucherListContain from "../../components/VoucherList";
import SubjectsPage from "../../pages/Subjects/SubjectsPage";
import AddSubjectsPage from "../../pages/Subjects/AddSubjectsPage";
import MemberListPage from "../../pages/MemberList";
import MemberDetailsTabs from "../../pages/memberDetailsTab";
import InvoiceListPage from "../../pages/InvoiceList"
import InvoiceDetailsPage from "../../pages/InvoiceDetails"
export const routes = [
  {
    path: `${process.env.PUBLIC_URL}`,
    Component: (
      <>
        <h1>Hello Default</h1>
      </>
    ),
  },
  {
    path: `${process.env.PUBLIC_URL}/`,
    Component: (
      <>
        <h1>Hello Default</h1>
      </>
    ),
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard`,
    Component: <Dashboard />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tutor-list`,
    Component: <TutorList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/payment-info/:id`,
    Component: <TutorPaymentInfo />,
  },
  {
    path: `${process.env.PUBLIC_URL}/create-voucher`,
    Component: <CreateVoucherContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/voucher-list`,
    Component: <VoucherListContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/subjects`,
    Component: <SubjectsPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/add-subject`,
    Component: <AddSubjectsPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/update-subject/:id`,
    Component: <AddSubjectsPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/member-list`,
    Component: <MemberListPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/member-details/:id`,
    Component: <MemberDetailsTabs />,
  },
  {
    path: `${process.env.PUBLIC_URL}/invoice-list`,
    Component: <InvoiceListPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/invoice-details`,
    Component: <InvoiceDetailsPage />,
  },
];
