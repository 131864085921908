import React, { Fragment, useState } from "react";
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Card,
} from "reactstrap";
import ActiveVoucher from "./ActiveVoucher";
import ExpiredVoucher from "./ExpiredVoucher";

const VoucherList = () => {
  const [VerticalTab, setVerticalTab] = useState("1");
  return (
    <Fragment>
      <Card>
        <Container fluid={true}>
          <Row style={{ display: "flex", alignItems: "center" }}>
            <Col sm="4">
              <h3 style={{ color: "#2a6a7a", fontSize: "22px" }}>
                Voucher List
              </h3>
            </Col>
            <Col sm="8">
              <Nav className="nav  nav-pills d-flex justify-content-start">
                <NavItem>
                  <NavLink
                    style={{
                      cursor: "pointer",
                      background: VerticalTab === "1" ? "#ff8819" : "",
                    }}
                    className={VerticalTab === "1" ? "active" : ""}
                    onClick={() => setVerticalTab("1")}
                  >
                    <i className="icofont icofont-ui-calendar"></i>
                    {"Active Voucher"}
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{
                      cursor: "pointer",
                      background: VerticalTab === "2" ? "#ff8819" : "",
                    }}
                    className={VerticalTab === "2" ? "active" : ""}
                    onClick={() => setVerticalTab("2")}
                  >
                    <i className="icofont icofont-listing-number"></i>
                    {"Expired Voucher"}
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="12" xl="12 box-col-12" className="px-0">
              <TabContent activeTab={VerticalTab}>
                <TabPane tabId="1">
                  <ActiveVoucher />
                </TabPane>
                <TabPane tabId="2">
                  <ExpiredVoucher />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </Card>
    </Fragment>
  );
};
export default VoucherList;
