import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Media, Row } from "reactstrap";
import { PayableTutorListGetApi, ProfilePictureRootURL } from "../../api";
import man from "../../assets/images/avtar/man.png";
import { Image, LI, UL } from "../../shared/AbstractElements";

export default function TutorListContain() {
  const [payableTutorList, setPayableTutorList] = useState([]);
  const userData = JSON.parse(localStorage.getItem("adminData"));
  const localStorageToken = userData.token;

  const fetchPayableTutorsData = async () => {
    const res = await axios.get(PayableTutorListGetApi, {
      headers: {
        authorization: `Bearer ${localStorageToken}`,
      },
    });
    // console.log("data", res.data);
    if (res.data) {
      setPayableTutorList(res.data);
    }
  };

  useEffect(() => {
    fetchPayableTutorsData();
  }, []);
  return (
    <div>
      <Fragment>
        <h3 className="mb-4">Payable Tutor List</h3>
        <Col xl="12" className="">
          <Row className="search-page">
            <div
              className="search-links tab-pane fade show active"
              id="all-links"
              role="tabpanel"
              aria-labelledby="all-link"
            >
              <Row>
                <Col xl="12 box-col-12 search-banner">
                  {payableTutorList.map((item) => (
                    <Card
                      style={{
                        width: "50%",
                        boxShadow:
                          "rgba(0, 0, 0, 0.2) 0px 1px 1px 0px, rgba(0, 0, 0, 0.19) 0px 0px 0px 0px",
                        marginBottom: "2px",
                        height: "130px",
                      }}
                    >
                      <div className="info-block p-3 mt-0 mb-2 single_tutor">
                        <Media>
                          <Link
                            className="d-none"
                            to={`/payment-info/${item._id}`}
                          >
                            <Image
                              attrImage={{
                                className:
                                  "img-40 img-fluid m-r-20 rounded-circle",
                                src: `${
                                  item.loginType === "pass"
                                    ? ` ${
                                        ProfilePictureRootURL +
                                        item.profilePicture
                                      }`
                                    : item.profilePicture
                                    ? item.profilePicture
                                    : man
                                }`,
                                alt: "",
                              }}
                            />
                          </Link>
                          <Media body>
                            <h3 className="tutor_name">
                              <Link to={`/payment-info/${item.user}`}>
                                {item.firstName} {item.lastName}
                              </Link>
                            </h3>

                            <p className="" style={{ textAlign: "justify" }}></p>
                            <div className="star-ratings">
                              <UL
                                attrUL={{
                                  className: "simple-list search-info flex-row",
                                }}
                              >
                                <LI>
                                  <span className="badge badge-light-primary py-1" style={{ fontSize: "11px" }}>
                                    Payable Amount : {item.payableAmount.toFixed(2)}
                                  </span>
                                </LI>
                              </UL>
                            </div>
                          </Media>
                        </Media>
                      </div>
                    </Card>
                  ))}
                </Col>
              </Row>
            </div>
          </Row>
        </Col>
      </Fragment>
    </div>
  );
}
