import React, { Fragment } from "react";
import InvoiceList from "../../components/InvoiceList/InvoiceList";

const InvoiceListPage = (e) => {
    return (
        <Fragment>
            <InvoiceList />
        </Fragment>
    );
};
export default InvoiceListPage;
