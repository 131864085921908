export const ROOT = process.env.REACT_APP_ROOT_ENVIRONMENT;

// login url
export const LoginApi = `${ROOT}/api/admin/login`;

// auth url
export const AuthApi = `${ROOT}/api/admin/auth`;

//Profile pic and attachment url
export const ProfilePictureRootURL = `${ROOT}/document/image/`;
export const DocumentRootURL = `${ROOT}/document/document/`;

//get all payable tutor list
export const PayableTutorListGetApi = `${ROOT}/api/admin/getTutor`;

//tutor details
export const TutorDetailsGetApi = `${ROOT}/api/admin/getTutorDetails`;

//tutor's payable amount details
export const TutorPayableAmountGetApi = `${ROOT}/api/admin/getTutorPaymentDetails`;

//tutor's payout details
export const TutorPayoutGetApi = `${ROOT}/api/admin/getTutorPayoutDetails`;

//tutor's payout done update
export const TutorPayoutDoneUpdateApi = `${ROOT}/api/admin/updatePayableAmount`;
export const InvoiceCreationApi = `${ROOT}/api/admin/create-invoice`;
export const CreateDynamicVoucherApi = `${ROOT}/api/payment/createDynamicVoucher`;

export const ActiveVoucherListGetApi = `${ROOT}/api/admin/getActiveVoucheres`;
export const ExpiredVoucherListGetApi = `${ROOT}/api/admin/getExpiredVoucheres`;
export const VoucherUpdateApi = `${ROOT}/api/admin/updateVoucher`;

//Subjects apis
export const AllSubjectFetchApi = `${ROOT}/api/admin/subjectList`;
export const AddSubjectApi = `${ROOT}/api/admin/addSubject`;
export const UpdateSubjectApi = `${ROOT}/api/admin/updateSubject/`;

//Member list and member details apis
export const MemberListGetApi = `${ROOT}/api/admin/memberList/`;
export const MemberDetailsGetApi = `${ROOT}/api/admin/member-info/`;
export const AllDegreeLevels = `${ROOT}/api/admin/getDegreeLevels`;
export const UpdateTutorInterestedToTech = `${ROOT}/api/admin/tutor-tuition-update/`;
export const UpdateStudentInterestedToLearn = `${ROOT}/api/admin/student-tuition-update/`;
export const StudentTransactionSummeryApi = `${ROOT}/api/admin/payment-summary/`;

//Member Education info update apis
export const UpdateMemberEducationInfo = `${ROOT}/api/admin/updateEduInfo`;

//Get Course and Degree List

export const GetCourseAndDegreeList = `${ROOT}/api/admin/getAllDegreesAndCourses`;
