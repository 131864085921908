import React, { Fragment } from "react";
import InvoiceDetails from "../../components/InvoiceList/InvoiceDetails";

const InvoiceDetailsPage = (e) => {
    return (
        <Fragment>
            <InvoiceDetails />
        </Fragment>
    );
};
export default InvoiceDetailsPage;
