import React, { Fragment, useContext, useEffect, useState } from "react";

import { Row } from "reactstrap";
import imgg from "../../../assets/images/logo/logo.png";
import { Image } from "../../../shared/AbstractElements";
import { ToggleIconss } from "../../../shared/svgIcons";
import CheckContext from "../../../shared/_helper/customizer/index";
import HeaderContain from "./HeaderContain";
import Searchbar from "./SearchBar";

const Header = () => {
  const { toggleSidebar } = useContext(CheckContext);
  const [toggle, setToggle] = useState(false);

  const openCloseSidebar = () => {
    setToggle(!toggle);
    toggleSidebar(toggle);
  };
  useEffect(() => {
    openCloseSidebar();
  }, []);
  return (
    <Fragment>
      <Row className="header-wrapper m-0">
        <div className="header-logo-wrapper col-auto p-0" id="out_side_click">
          <div className="logo-wrapper">
            <a href="#1">
              <Image
                attrImage={{
                  className: "img-fluid",
                  src: `${imgg}`,
                  alt: "12314211",
                }}
              />
            </a>
          </div>
          <div
            className="toggle-sidebar"
            onClick={() => openCloseSidebar()}
            id="sidebar-toggle"
          >
            <i className="status_toggle sidebar-toggle d-flex">
              <ToggleIconss />
            </i>
          </div>
        </div>
        <Searchbar />
        <HeaderContain />
      </Row>
    </Fragment>
  );
};
export default Header;
