export function convertToTitleCase(inputString) {
    if (!inputString || typeof inputString !== "string") {
      return "";
    }
  
    const trimmedString = inputString.trim();
    if (trimmedString.length === 0) {
      return "";
    }
  
    return trimmedString.charAt(0).toUpperCase() + trimmedString.slice(1);
  }