import {
  HomeSvg,
  AdminUsersSvg,
  JobsearchSvg,
  SamplePageSvg,
  FormsSvg,
  FilemanagerSvg
} from "../../../shared/svgIcons";

/*
0: both
1: tutor
2: student
*/
const ALLMENU = [
  // {
  //   id: 1,
  //   menutitle: "DashBoard",
  //   Items: [
  //     {
  //       path: `${process.env.PUBLIC_URL}/dashboard`,
  //       icon: HomeSvg,
  //       title: "DashBoard",
  //       type: "link",
  //     },
  //   ],
  //   userType: "0",
  // },
  {
    id: 1,
    menutitle: "TutorList",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/tutor-list`,
        icon: HomeSvg,
        title: "Payable Tutor List",
        type: "link",
      },
    ],
    userType: "0",
  },
  {
    id: 2,
    menutitle: "Create Voucher",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/create-voucher`,
        icon: FormsSvg,
        title: "Create Voucher",
        type: "link",
      },
    ],
    userType: "0",
  },
  {
    id: 3,
    menutitle: "Voucher List",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/voucher-list`,
        icon: SamplePageSvg,
        title: "Voucher List",
        type: "link",
      },
    ],
    userType: "0",
  },
  {
    id: 4,
    menutitle: "Subjects",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/subjects`,
        icon: SamplePageSvg,
        title: "Subjects",
        type: "link",
      },
    ],
    userType: "0",
  },
  {
    id: 5,
    menutitle: "MemberList",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/member-list`,
        icon: AdminUsersSvg,
        title: "Member List",
        type: "link",
      },
    ],
    userType: "0",
  },
  // {
  //   id: 6,
  //   menutitle: "Invoices",
  //   Items: [
  //     {
  //       path: `${process.env.PUBLIC_URL}/invoice-list`,
  //       icon: FilemanagerSvg,
  //       title: "Invoices",
  //       type: "link",
  //     },
  //   ],
  //   userType: "0",
  // },
];

export const MENUITEMS2 = ALLMENU;
