import axios from "axios";
import React, { useEffect, useState } from "react";
import { AllSubjectFetchApi } from "../../api";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import {
  Table,
  Card,
  Pagination,
  PaginationItem,
  PaginationLink,
  Spinner,
} from "reactstrap";
import CommonPagination from "../common/Pagination/CommonPagination";
import { FaEdit } from "react-icons/fa";

const Subjects = () => {
  const [allSubjects, setAllSubjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;

  useEffect(() => {
    fetchSubjects();
  }, []);

  const fetchSubjects = async () => {
    const userData = JSON?.parse(localStorage.getItem("adminData"));
    const localStorageToken = userData?.token;
    if (!localStorageToken) {
      toast.warn("Invalid Token!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      return;
    }
    try {
      const res = await axios.get(AllSubjectFetchApi, {
        headers: {
          authorization: `Bearer ${localStorageToken}`,
        },
      });
      // console.log("data", res.data);
      if (res?.data && res?.data?.length > 0) {
        setAllSubjects(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // updating section

  const handleSetSubjectInLocal = (id) => {
    if (allSubjects && id) {
      const selectedSubject = allSubjects.find((item) => item.id === id);
      localStorage.setItem("updateSubject", JSON.stringify(selectedSubject));
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const paginationData = allSubjects?.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (
    let i = Math.max(1, currentPage - 2);
    i <=
    Math.min(Math.ceil(allSubjects?.length / itemsPerPage), currentPage + 2);
    i++
  ) {
    pageNumbers.push(i);
  }

  return (
    <Card style={{ minHeight: "100vh", padding: "11px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <h3 style={{ color: "#2a6a7a", fontSize: "22px" }}>Subject List</h3>
        </div>

        <div>
          <Link to="/add-subject">
            <button
              type="button"
              className="btn  fw-semibold fs-6 ms-5"
              style={{
                background: "#ff8819",
                color: "#ffffff",
                padding: "4px 8px",
              }}
            >
              Add New Subjects
            </button>
          </Link>
        </div>
      </div>

      <div>
        <div>
          {allSubjects?.length}{" "}
          {allSubjects?.length > 1 ? "Subjests" : "Subject"} Found
        </div>
      </div>

      <Table
        style={{
          background: "#ffffff",
          boxShadow: "0px 0px white",
          borderRadius: "0px",
          border: "0px",
        }}
      >
        <thead>
          <tr style={{ borderBottom: "3px solid black" }}>
            <th>SL</th>
            <th scope="col">Name</th>
            <th scope="col">Category/Sub-Category</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        {allSubjects && allSubjects?.length > 0 ? (
          <tbody>
            {paginationData &&
              paginationData?.length > 0 &&
              paginationData?.map((item, idx) => (
                <tr
                  key={item?.id}
                  style={{ borderBottom: "2px solid lightgrey" }}
                >
                  <th scope="row">{idx + 1 ?? ""}</th>
                  <td className="fw-normal">{item?.name ?? ""}</td>
                  <td className="fw-normal">{item?.tag ?? ""}</td>
                  <td>
                    <Link
                      onClick={() => handleSetSubjectInLocal(item?.id)}
                      to={`/update-subject/${item?.id}`}
                    >
                      {/* <button
                      type="button"
                      className="btn btn-primary fw-semibold"
                    >
                      Edit
                    </button> */}
                      <FaEdit />
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan="4" style={{ textAlign: "center" }}>
                <h3 style={{ color: "#ff8819", marginTop: "50px" }}>
                  No subject to show
                </h3>
              </td>
            </tr>
          </tbody>
        )}
      </Table>

      <CommonPagination
        currentPage={currentPage}
        totalPages={Math.ceil(allSubjects.length / itemsPerPage)}
        paginate={setCurrentPage}
      />
    </Card>
  );
};

export default Subjects;
