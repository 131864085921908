import React, { Fragment } from "react";
import MemberDetailsTabsContain from "../../components/MemberList/MemberDetailsTabs/MemberDetailsTabs";

const MemberDetailsTabs = (e) => {
  return (
    <Fragment>
      <MemberDetailsTabsContain />
    </Fragment>
  );
};
export default MemberDetailsTabs;
