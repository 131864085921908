import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/img/tuteair-logo.v2.svg";
import { Image } from "../../../shared/AbstractElements";
import CheckContext from "../../../shared/_helper/customizer/index";

const SidebarIcon = () => {
  const { toggleSidebar } = useContext(CheckContext);
  const [toggle, setToggle] = useState(false);
  const openCloseSidebar = () => {
    setToggle(!toggle);
    toggleSidebar(toggle);
  };
  return (
    <>
      <div className="logo-wrapper">
        <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
          <span className="d-flex justify-content-center align-items-center">
            <Image
              attrImage={{
                className: "w-25 h-25",
                src: `${logo}`,
                alt: "tuteair-logo",
              }}
            />
            <h1 style={{ color: "#000000de" }} className="ps-1">
              TuteAir
            </h1>
            {/* <Image
            attrImage={{
              className: "img-fluid for-dark h-100",
              src: `${logo1}`,
              alt: "tuteair-logo",
            }}
          /> */}
          </span>
        </Link>
        <div className="back-btn" onClick={() => openCloseSidebar()}>
          <i className="fa fa-angle-left"></i>
        </div>
      </div>
    </>
  );
};
export default SidebarIcon;
