import React, { useState } from "react";
import Context from "./index";

const FilterProvider = (props) => {
  const [moonlight, setMoonlight] = useState(false);
  const [themeReminder, setThemeReminder] = useState(false);

  return (
    <Context.Provider
      value={{
        ...props,
        moonlight,
        setMoonlight,
        themeReminder,
        setThemeReminder,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};
export default FilterProvider;
