import React, { Fragment } from "react";
import TutorPaymentInfoContain from "../../components/TutorPaymentInfo/TutorPaymentInfo";

const TutorPaymentInfo = (e) => {
  return (
    <Fragment>
      <TutorPaymentInfoContain />
    </Fragment>
  );
};
export default TutorPaymentInfo;
