import React, { Fragment } from "react";
import DashboardContain from "../../components/dashboard/dashboard";

const Dashboard = (e) => {
  return (
    <Fragment>
      <DashboardContain />
    </Fragment>
  );
};
export default Dashboard;
