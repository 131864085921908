import React, { Fragment, useState } from "react";
import { Lock, Mail } from "react-feather";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Form, FormGroup, Input, InputGroup, Row } from "reactstrap";
import { H5, H6, P, UL, H3 } from "../../../shared/AbstractElements";
import axios from "axios";
import { LoginApi } from "../../../api";
import loginImage from "../../../assets/img/loginImage.avif";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [togglePassword, setTogglePassword] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      toast.warn("Invalid Credentials!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      return;
    }
    setIsLoading(true);
    try {
      const res = await axios.post(LoginApi, {
        email,
        password,
      });
      // console.log(res);
      const data = res.data;
      // console.log('data', data);

      if (res.status === 200 && data.role === "admin") {
        setIsLoading(false);
        localStorage.setItem("adminData", JSON.stringify(data));
        toast.success("Login Successful");
        navigate("/tutor-list");
      }
    } catch (error) {
      setIsLoading(false);
      toast.warn("Something went wrong");
      console.error(error);
      navigate("/sign-in");
    }
  };

  return (
    <Fragment>
      <div className="py-0 py-md-5" style={{ background: "#737a8a00" }}>
        <section className={`${false ? "" : "container"}`}>
          <Row xs="1" md="2" className="m-0">
            <Col
              xs={{ order: 1 }}
              md={{ order: 2 }}
              className="p-0 carousal-page mt-5 mt-md-0"
              style={{ placeSelf: "center" }}
            >
              <img style={{ width: "100%" }} src={loginImage} />
              {/* <SlickSlider
              slides={1}
              title="Responsive Example"
              rtl={false}
              lazyLoad={true}
            /> */}
            </Col>
            <Col xs={{ order: 1 }} md={{ order: 2 }} className="p-0">
              <div className="login-card1 become_tutor_card1">
                <Form className="theme-form login-form">
                  <div className="login-header  text-center">
                    {/* <H4>
            Login <HelpModalBtn showIconAlways={true} />
          </H4> */}
                    <H6>Welcome back! Log in to your admin account.</H6>
                    <H3>Admin</H3>
                  </div>
                  <FormGroup>
                    <UL
                      attrUL={{
                        className: "simple-list login-social flex-row",
                      }}
                    ></UL>
                    <div className="mt-3">
                      {Option}
                      <div
                        style={{ fontSize: "13px" }}
                        className="digits text-center d-none"
                      >
                        By signing in with Google I agree that I have read{" "}
                        <Link
                          to={`${process.env.PUBLIC_URL}/privacy`}
                          target="_blank"
                        >
                          Privacy Policy
                        </Link>
                        <span className="px-1">and</span>
                        <Link
                          to={`${process.env.PUBLIC_URL}/terms-condition`}
                          target="_blank"
                        >
                          Terms and Condition
                        </Link>
                      </div>
                    </div>
                  </FormGroup>
                  <div className="login-social-title">
                    <H5>Sign in with Email</H5>
                  </div>
                  <FormGroup>
                    <label className="form-label">Email Address</label>
                    <InputGroup>
                      <span className="input-group-text">
                        <Mail />
                      </span>
                      <Input
                        style={{ color: "black" }}
                        className="form-control"
                        type="email"
                        required=""
                        placeholder="user@gmail.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <label className="form-label">Password</label>
                    <InputGroup>
                      <span className="input-group-text">
                        <Lock />
                      </span>
                      <Input
                        style={{ color: "black" }}
                        className="form-control"
                        type={togglePassword ? "text" : "password"}
                        name="login[password]"
                        required=""
                        placeholder="*********"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <div
                        className="show-hide"
                        onClick={() => setTogglePassword(!togglePassword)}
                      >
                        <span className={togglePassword ? "" : "show"}></span>
                      </div>
                    </InputGroup>
                  </FormGroup>

                  <FormGroup className="login-btn form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="checkbox d-none">
                          <Input id="checkbox1" type="checkbox" />
                          <label className="text-muted" htmlFor="checkbox1">
                            Remember Password
                          </label>
                        </div>
                        <div className="" style={{ float: "left" }}>
                          <Link
                            to={`${process.env.PUBLIC_URL}/forgot-password`}
                            className="link"
                          >
                            Forgot Password?
                          </Link>
                        </div>
                      </div>
                      <div className="col-md-6"></div>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={loading}
                      onClick={(e) => handleLogin(e)}
                    >
                      {loading ? "LOADING..." : "Sign In"}
                    </button>
                  </FormGroup>
                  {/* <P>
                    Don't have account?
                    <Link to={`${process.env.PUBLIC_URL}/register-v2`} className="ms-2">
                      {"Create Account"}
                    </Link>
                    <Link to={`${process.env.PUBLIC_URL}/home`} className="ms-2">
                      | Home
                    </Link>
                  </P> */}
                  {/* <HelpModal heading="Sign In" body={<LogInGuide />} /> */}
                </Form>
              </div>
            </Col>
          </Row>
          <Row></Row>
        </section>
      </div>
    </Fragment>
  );
};

export default Login;
