import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { GetCourseAndDegreeList } from "../../api";
import { useMemberDetailsContext } from "../../shared/_helper/MemberDetails/MemberDetailsProvider";

const getCourseAndDegreeList = async () => {
  const userData = JSON.parse(localStorage.getItem("adminData"));
  const localStorageToken = userData.token;
  const response = await axios.get(`${GetCourseAndDegreeList}`, {
    headers: {
      Authorization: `Bearer ${localStorageToken}`,
    },
  });
  return response;
};

export const useCourseAndDegreeList = () => {
  const { setCourseList, setDegreeList } = useMemberDetailsContext();

  const { data, isLoading } = useQuery({
    queryKey: ["member-list"],
    queryFn: () => getCourseAndDegreeList(),

    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (data?.data) {
        setDegreeList(data?.data?.data?.degrees);
        setCourseList(data?.data?.data?.courses);
      }

      return data;
    },
    onError: (err) => {
      console.log("useCourseAndDegreeList error", err);
      return err;
    },
  });

  return { data, isLoading };
};
