import { defaultOperators } from "react-querybuilder";
import { countries } from "./countries";

export const validator = (r) => !!r.value;

export const fields = [
  {
    name: "totalClasses",
    label: "Total Classes",
    inputType: "number",
  },
  {
    name: "totalStudents",
    label: "Total Students",
    inputType: "number",
  },
  {
    name: "currentStudents",
    label: "Current Students",
    inputType: "number",
  },
  {
    name: "country",
    label: "Country",
    valueEditorType: "select", //multiselect
    values: countries,
    defaultValue: "Bangladesh",
  },
  {
    name: "isCertified",
    label: "Certified Tutor",
    valueEditorType: "checkbox",
    operators: defaultOperators.filter((op) => op.name === "="),
    defaultValue: false,
  },
  {
    name: "emailVerified",
    label: "Email Verified",
    valueEditorType: "checkbox",
    operators: defaultOperators.filter((op) => op.name === "="),
    defaultValue: false,
  },

  {
    name: "memberType",
    label: "Member Type",
    operators: defaultOperators.filter((op) => op.name === "="),
    valueEditorType: "radio",
    values: [
      { name: "tutor", label: "Tutor" },
      { name: "student", label: "Student" },
      { name: "both", label: "Both" },
    ],
  },
  {
    name: "gender",
    label: "Gender",
    operators: defaultOperators.filter((op) => op.name === "="),
    valueEditorType: "radio",
    values: [
      { name: "male", label: "Male" },
      { name: "female", label: "Female" },
      { name: "other", label: "Other" },
    ],
  },
  // {
  //   name: "Language",
  //   label: "Language",
  // },

  // {
  //   name: "firstName",
  //   label: "First Name",
  //   placeholder: "Enter first name",
  //   validator,
  // },
  // {
  //   name: "lastName",
  //   label: "Last Name",
  //   placeholder: "Enter last name",
  //   defaultOperator: "beginsWith",
  //   validator,
  // },
  // { name: "age", label: "Age", inputType: "number", validator },
  // {
  //   name: "isMusician",
  //   label: "Is a musician",
  //   valueEditorType: "checkbox",
  //   operators: defaultOperators.filter((op) => op.name === "="),
  //   defaultValue: false,
  // },

  // { name: "height", label: "Height", validator },
  // { name: "job", label: "Job", validator },
  // { name: "description", label: "Description", valueEditorType: "textarea" },
  // { name: "birthdate", label: "Birth Date", inputType: "date" },
  // { name: "datetime", label: "Show Time", inputType: "datetime-local" },
  // { name: "alarm", label: "Daily Alarm", inputType: "time" },
  // {
  //   name: "groupedField1",
  //   label: "Grouped Field 1",
  //   comparator: "groupNumber",
  //   groupNumber: "group1",
  //   valueSources: ["field", "value"],
  // },
  // {
  //   name: "groupedField2",
  //   label: "Grouped Field 2",
  //   comparator: "groupNumber",
  //   groupNumber: "group1",
  //   valueSources: ["field", "value"],
  // },
  // {
  //   name: "groupedField3",
  //   label: "Grouped Field 3",
  //   comparator: "groupNumber",
  //   groupNumber: "group1",
  //   valueSources: ["field", "value"],
  // },
  // {
  //   name: "groupedField4",
  //   label: "Grouped Field 4",
  //   comparator: "groupNumber",
  //   groupNumber: "group1",
  //   valueSources: ["field", "value"],
  // },
];
