import React, { Fragment } from "react";
import TutorListContain from "../../components/tutorList/TutorList";

const TutorList = (e) => {
  return (
    <Fragment>
      <TutorListContain />
    </Fragment>
  );
};
export default TutorList;
