import React from "react";
import VoucherList from "./VoucherList";

const VoucherListContain = () => {
  return (
    <div>
      <VoucherList />
    </div>
  );
};

export default VoucherListContain;
