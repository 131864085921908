import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { StudentTransactionSummeryApi } from "../api";


const getStudentTransactionSummery = async ({userId }) => {
  const userData = JSON.parse(localStorage.getItem("adminData"));
  const localStorageToken = userData.token;
  const response = await axios.get(`${StudentTransactionSummeryApi}${userId}`, {
    headers: {
      Authorization: `Bearer ${localStorageToken}`,
    },
  });
  return response;
};

export const useStudentTransactionSummery = (userId) => {
  const { data, isLoading } = useQuery({
    queryKey: ["transaction-summery",userId],
    queryFn: () => getStudentTransactionSummery({userId }),

    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      return data;
    },
    onError: (err) => {
      console.log("useStudentTransactionSummery error", err);
      return err;
    },
  });

  return { data, isLoading };
};
