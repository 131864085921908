import React, { useState } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import axios from "axios";
import { QueryBuilder } from "react-querybuilder";
import { fields } from "./fields";
import "react-querybuilder/dist/query-builder.scss";
import "./VoucherForm.css";
import { CreateDynamicVoucherApi } from "../../api/index";
import allowedOperatorsList from "./operators";
import { toastNotify } from "../../Services/ToastNotify";

const VoucherForm = () => {
  const userData = JSON.parse(localStorage.getItem("adminData"));
  const localStorageToken = userData.token;
  const initialQuery = { combinator: "and", rules: [] };
  const initialVoucherDetails = {
    voucherCode: "",
    createdBy: userData.firstName + " " + userData.lastName,
    expiresAt: "",
    maxUsageCount: "",
    discountType: "fixed", // "fixed" or "percentage"
    discountAmount: "",
    maxDiscountAmount: "",
  };
  const [voucherDetails, setVoucherDetails] = useState(initialVoucherDetails);
  const [query, setQuery] = useState(initialQuery);

  const resetForm = () => {
    setVoucherDetails(initialVoucherDetails);
    setQuery(initialQuery);
  };

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === 'partialFixedVoucher') {
      setVoucherDetails((prevDetails) => ({
        ...prevDetails,
        [name]: checked,
      }));
      return;
    }
    setVoucherDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = {
        ...voucherDetails,
        rules: query.rules,
        adminId: userData._id,
      };

      const res = await axios.post(CreateDynamicVoucherApi, formData, {
        headers: {
          authorization: `Bearer ${localStorageToken}`,
        },
      });

      if (res?.status === 200) {
        toastNotify(res?.data?.message, "success");

        resetForm();
      }
    } catch (error) {
      toastNotify(
        "Failed to create the voucher. Please verify the inputs and try again.",
        "danger"
      );
    }
  };

  return (
    <Form className="voucher-form" onSubmit={handleSubmit}>
      {/* Voucher details inputs */}
      <div className="voucher-details">
        <h2>Voucher Details</h2>
        <FormGroup>
          <Label for="voucherCode">Voucher Code:</Label>
          <Input
            type="text"
            id="voucherCode"
            name="voucherCode"
            value={voucherDetails.voucherCode}
            onChange={handleInputChange}
          />
        </FormGroup>

        {/* Discount Type */}
        <FormGroup>
          <Label for="discountType">Discount Type:</Label>
          <Input
            type="select"
            id="discountType"
            name="discountType"
            value={voucherDetails.discountType}
            onChange={handleInputChange}
          >
            <option value="fixed">Fixed Amount</option>
            <option value="percentage">Percentage</option>
          </Input>
        </FormGroup>
        {/* partially remaining voucher  */}
        {
          voucherDetails?.discountType === 'fixed' &&
          <FormGroup>
            <Label for="partialFixedVoucher">Partially Reusable:</Label>
            <Input
              className="ms-2 mb-2"
              type="checkbox"
              id="partialFixedVoucher"
              name="partialFixedVoucher"
              value={voucherDetails?.partialFixedVoucher}
              onChange={handleInputChange}
            >
            </Input>
          </FormGroup>
        }

        {/* Discount Amount */}
        {voucherDetails.discountType === "percentage" ? (
          <>
            <FormGroup>
              <Label for="discountAmount">Discount Percentage:</Label>
              <Input
                type="text"
                id="discountAmount"
                name="discountAmount"
                value={voucherDetails.discountAmount}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="maxDiscountAmount">Max Discount Amount:</Label>
              <Input
                type="text"
                id="maxDiscountAmount"
                name="maxDiscountAmount"
                value={voucherDetails.maxDiscountAmount}
                onChange={handleInputChange}
              />
            </FormGroup>
          </>
        ) : (
          <FormGroup>
            <Label for="discountAmount">Discount Amount:</Label>
            <Input
              type="number"
              id="discountAmount"
              name="discountAmount"
              min="0"
              value={voucherDetails.discountAmount}
              onChange={handleInputChange}
            />
          </FormGroup>
        )}

        <FormGroup>
          <Label for="expiresAt">Expiration Date:</Label>
          <Input
            type="date"
            id="expiresAt"
            name="expiresAt"
            value={voucherDetails.expiresAt}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="maxUsageCount">Max Usage Count:</Label>
          <Input
            type="number"
            id="maxUsageCount"
            name="maxUsageCount"
            min="1"
            value={voucherDetails.maxUsageCount}
            onChange={handleInputChange}
          />
        </FormGroup>
        {/* <FormGroup>
          <Label for="usageCount">Usage Count:</Label>
          <Input
            type="number"
            id="usageCount"
            name="usageCount"
            min="1"
            value={voucherDetails.usageCount}
            onChange={handleInputChange}
          />
        </FormGroup> */}
      </div>

      {/* Dynamic rules section */}
      <div className="rules-section">
        <h2>Rules</h2>
        <QueryBuilder
          fields={fields}
          query={query}
          onQueryChange={(q) => setQuery(q)}
          operators={allowedOperatorsList}
          controlElements={{
            addGroupAction: () => null,
            combinatorSelector: () => null,
          }}
        />
      </div>

      <Button type="submit">Create Voucher</Button>
    </Form>
  );
};

export default VoucherForm;
