import React, { Fragment } from "react";
import MemberList from "../../components/MemberList/MemberList";

const MemberListPage = (e) => {
  return (
    <Fragment>
      <MemberList />
    </Fragment>
  );
};
export default MemberListPage;
