import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  FormText,
  Row,
  Col,
  Card,
} from "reactstrap";
import Multiselect from "multiselect-react-dropdown";

export default function Address() {
  return (
    <div>
      <Card style={{ minHeight: "100vh", padding: "11px" }}>
        <div>
          <h3
            style={{
              color: "#2a6a7a",
              fontSize: "26px",
              marginBottom: "20px",
              marginTop: "10px",
            }}
          >
            Member Address
          </h3>
        </div>
        <Row>
          <Col md={12}>
            <FormGroup className="w-full md:w-80">
              <Label for="tuitionTitle">Address Line 1</Label>
              <Input
                style={{ padding: "14px" }}
                type="text"
                name="tuitionTitle"
                id="tuitionTitle"
                //   value={subjectTitle}
                //   onChange={(e) => setSubjectTitle(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup className="w-full md:w-80">
              <Label for="tuitionType">Address Line 2</Label>

              <Input
                style={{ padding: "14px" }}
                type="text"
                name="tuitionTitle"
                id="tuitionTitle"
                //   value={subjectTitle}
                //   onChange={(e) => setSubjectTitle(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup className="w-full md:w-80">
              <Label for="level">Area</Label>
              <Input
                style={{ padding: "14px" }}
                type="text"
                name="tuitionTitle"
                id="tuitionTitle"
                //   value={subjectTitle}
                //   onChange={(e) => setSubjectTitle(e.target.value)}
              />

              {/* Render options here */}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup className="w-full md:w-80">
              <Label for="level">City</Label>
              <Input
                style={{ padding: "14px" }}
                type="text"
                name="level"
                id="level"
                //   value={classesName}
                //   onChange={handleChangeLevel}
              >
                {/* {classList.map((name) => (
                    <option key={name.value} value={name.label}>
                      {name.label}
                    </option>
                  ))} */}
              </Input>
            </FormGroup>
          </Col>
          <Col md={6} display="flex">
            <FormGroup>
              <Label for="tuitionTitle">Post Code</Label>
              <span style={{ display: "flex", alignItems: "baseline" }}>
                <Input
                  style={{ padding: "14px" }}
                  type="text"
                  name="amount"
                  id="amount"
                  // value={amount}
                  // onChange={(e) => {
                  //   e.target.value
                  //     ?.replace(/[^\d.]/g, "")
                  //     ?.match(/^(?!0\d)\d{0,6}(\.\d{0,2})?$/)[0] >= 0 &&
                  //     e.target.value
                  //       ?.replace(/[^\d.]/g, "")
                  //       ?.match(/^(?!0\d)\d{0,6}(\.\d{0,2})?$/)[0] <=
                  //       1000000 &&
                  //     setAmount(
                  //       e.target.value
                  //         ?.replace(/[^\d.]/g, "")
                  //         ?.match(/^(?!0\d)\d{0,6}(\.\d{0,2})?$/)[0]
                  //     );
                  // }}
                />
              </span>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup className="w-full md:w-80">
              <Label for="level">State</Label>
              <Input
                style={{ padding: "14px" }}
                type="text"
                name="level"
                id="level"
                //   value={classesName}
                //   onChange={handleChangeLevel}
              >
                {/* {classList.map((name) => (
                    <option key={name.value} value={name.label}>
                      {name.label}
                    </option>
                  ))} */}
              </Input>
            </FormGroup>
          </Col>
          <Col md={6} display="flex">
            <FormGroup>
              <Label for="tuitionTitle">Country</Label>
              <span style={{ display: "flex", alignItems: "baseline" }}>
                <Input
                  style={{ padding: "14px" }}
                  type="text"
                  name="amount"
                  id="amount"
                  // value={amount}
                  // onChange={(e) => {
                  //   e.target.value
                  //     ?.replace(/[^\d.]/g, "")
                  //     ?.match(/^(?!0\d)\d{0,6}(\.\d{0,2})?$/)[0] >= 0 &&
                  //     e.target.value
                  //       ?.replace(/[^\d.]/g, "")
                  //       ?.match(/^(?!0\d)\d{0,6}(\.\d{0,2})?$/)[0] <=
                  //       1000000 &&
                  //     setAmount(
                  //       e.target.value
                  //         ?.replace(/[^\d.]/g, "")
                  //         ?.match(/^(?!0\d)\d{0,6}(\.\d{0,2})?$/)[0]
                  //     );
                  // }}
                />
              </span>
            </FormGroup>
          </Col>
        </Row>
        <div style={{ textAlign: "end" }}>
          <Button color="secondary">Cancel</Button>
          <Button color="primary" style={{ marginLeft: "8px" }}>
            Update
          </Button>
        </div>
      </Card>
    </div>
  );
}
