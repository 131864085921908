import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { AuthApi } from "../../api";

const PrivateRoute = () => {
  const [loading, setLoading] = useState(true);
  const [authorized, setAuthorized] = useState(false);
  const navigate = useNavigate();

  useEffect(async () => {
    const tuteair = JSON.parse(localStorage.getItem("adminData"));
    if (!tuteair) {
      setAuthorized(false);
      setLoading(false);
      navigate('/sign-in');
      return;
    }
    const localStorageToken = tuteair.token;
    // console.log(localStorageToken);

    try {
      const res = await axios.get(AuthApi, {
        headers: {
          authorization: `Bearer ${localStorageToken}`
        }
      });
      if (res.status === 200 && res.data === 'Authorized') {
        setAuthorized(true);
        setLoading(false);
      }
    }
    catch (error) {
      console.error(error);
      setAuthorized(false);
      setLoading(false);
      navigate('/sign-in');
    }
  }, []);

  if (loading) {
    return (<div>
      Loading....
    </div>)
  };
  return authorized ? <Outlet /> : <Navigate exact to={`${process.env.PUBLIC_URL}/sign-in`} />;
};

export default PrivateRoute;
