import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const CommonPagination = ({ currentPage, totalPages, paginate }) => {
  const pageNumbers = [];
  const maxVisiblePages = 4;

  let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
  let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

  if (endPage - startPage < maxVisiblePages - 1) {
    startPage = Math.max(1, endPage - maxVisiblePages + 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <Pagination
      style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}
    >
      <PaginationItem disabled={currentPage <= 1}>
        <PaginationLink previous onClick={() => paginate(currentPage - 1)} />
      </PaginationItem>
      {pageNumbers.map((number) => (
        <PaginationItem key={number} active={number === currentPage}>
          <PaginationLink onClick={() => paginate(number)}>
            {number}
          </PaginationLink>
        </PaginationItem>
      ))}
      <PaginationItem disabled={currentPage >= totalPages}>
        <PaginationLink next onClick={() => paginate(currentPage + 1)} />
      </PaginationItem>
    </Pagination>
  );
};

export default CommonPagination;
