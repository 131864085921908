import React, { useState } from "react";
import { Table, Card, Spinner } from "reactstrap";
import InterestedToLearnEdit from "./InterestedToLearnEdit";

export default function StudentInterestedToLearn({
  interestToLearn,
  refetch,
  isLoading,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEditItem, setSelectedEditItem] = useState({});

  const handleEdit = (item) => {
    setModalOpen(true);
    setSelectedEditItem(item);
  };

  return (
    <div>
      <Card style={{ minHeight: "100vh", padding: "11px" }}>
        <h3
          style={{
            color: "#2a6a7a",
          }}
        >
          {" "}
          Student Interested to Learn
        </h3>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
              minHeight: "50vh",
            }}
          >
            <Spinner type="grow" color="primary" />
          </div>
        ) : (
          <>
            {interestToLearn?.length > 0 ? (
              <Table
                style={{
                  background: "#ffffff",
                  boxShadow: "0px 0px white",
                  borderRadius: "0px",
                  border: "0px",
                }}
              >
                <thead>
                  <tr style={{ borderBottom: "3px solid black" }}>
                    <th>SL</th>
                    <th>Subject</th>
                    <th>Degree</th>
                    <th>Tuition Type</th>
                    <th>Amount </th>
                  </tr>
                </thead>
                <tbody style={{ fontWeight: "500" }}>
                  {interestToLearn?.map((row, index) => (
                    <tr
                      key={index}
                      style={{ borderBottom: "2px solid lightgrey" }}
                    >
                      <th scope="row">{index + 1}</th>
                      <td>
                        {row.subjects?.map((item, index) => (
                          <span
                            className="badge-light-primary"
                            style={{ margin: "0px 4px 2px 0px" }}
                            key={index}
                          >
                            {item?.name}
                          </span>
                        ))}
                      </td>
                      <td>{row?.classes?.name}</td>
                      <td>
                        {row.tutionTypes?.map((item, index) => (
                          <span
                            className="badge-light-primary"
                            style={{ margin: "0px 4px 2px 0px" }}
                            key={index}
                          >
                            {item}
                          </span>
                        ))}
                      </td>
                      <td>
                        {row?.amount?.confirmPrice ?? row?.amount?.initialPrice}
                      </td>
                      <td className="px-1">
                        <button
                          className="btn px-1 py-0 btn-link"
                          onClick={() => handleEdit(row)}
                        >
                          <i className="fa fa-edit"></i>{" "}
                        </button>
                        {/* <button
                    className="btn px-1 py-0 btn-link text-danger"
                   onClick={(e) => handleInterestPricingInfoDelete(e, item.id)}
                  >
                    <i className="fa fa-trash"></i>{" "}
                  </button> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div
                style={{
                  color: "red",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "40vh",
                  fontSize: "20px",
                }}
              >
                No interested to learn show
              </div>
            )}
          </>
        )}
      </Card>
      <InterestedToLearnEdit
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        selectedEditItem={selectedEditItem}
        refetch={refetch}
      />
    </div>
  );
}
