import React, { useState } from "react";
import { Table, Card, Spinner } from "reactstrap";
import { DocumentRootURL } from "../../../../api";
import EducationInfoEditModal from "./EducationInfoEdit";

export default function EducationInfo({
  educationsInfo,
  refetch,
  isLoading,
  memberDateOfBirth,
  userId,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEditItem, setSelectedEditItem] = useState({});

  const handleEdit = (item) => {
    setModalOpen(true);
    setSelectedEditItem(item);
  };

  return (
    <div>
      <Card style={{ minHeight: "100vh", padding: "11px" }}>
        <h3
          style={{
            color: "#2a6a7a",
          }}
        >
          Education Info
        </h3>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
              minHeight: "50vh",
            }}
          >
            <Spinner type="grow" color="primary" />
          </div>
        ) : (
          <>
            {educationsInfo?.length > 0 ? (
              <Table
                style={{
                  background: "#ffffff",
                  boxShadow: "0px 0px white",
                  borderRadius: "0px",
                  border: "0px",
                  width: "100%",
                }}
              >
                <thead>
                  <tr style={{ borderBottom: "3px solid black" }}>
                    <th>SL</th>
                    <th>Institute</th>
                    <th>Degree / Courses</th>
                    <th>Attachments</th>
                    <th>Result </th>
                    <th>Completion Year </th>
                  </tr>
                </thead>

                <tbody style={{ fontWeight: "500" }}>
                  {educationsInfo?.map((row, index) => (
                    <tr
                      key={index}
                      style={{ borderBottom: "2px solid lightgrey" }}
                    >
                      <th scope="row">{index + 1}</th>
                      <td className="">
                        <span className="text-left">{row.institute}</span>
                      </td>
                      <td>
                        <p className="mb-0 px-1">{row?.degree?.label}</p>
                        <p className="mb-0 px-1" style={{ color: "#488374" }}>
                          <small>{row?.course?.label}</small>
                        </p>
                      </td>
                      <td>
                        {row.attachments && (
                          <a
                            style={{ cursor: "pointer" }}
                            className="text-left text-primary"
                            // href={DocumentRootURL + item.attachments.id}
                            href={
                              row.attachments?.attachmentInfo?.attachmentInfo
                                ?.secure_url ||
                              DocumentRootURL + row.attachments.id
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            {row?.attachments?.attachmentInfo?.attachmentInfo
                              ?.secure_url
                              ? row?.attachments?.attachmentInfo?.attachmentInfo?.secure_url
                                  ?.split("/")
                                  .pop()
                              : row.attachments.name &&
                                row.attachments.name.substring(
                                  row.attachments.name.indexOf("_") + 1
                                )}

                            {/* {item.attachments.name &&
                                  item.attachments.name.substring(
                                    item.attachments.name.indexOf("_") + 1
                                  )} */}
                          </a>
                        )}
                      </td>
                      <td>{row.marks}%</td>
                      <td>
                        {row.passingYear}
                        {row.isRunning &&
                        row.isRunning === "true" &&
                        new Date().getFullYear() <= parseInt(row.passingYear)
                          ? " (In Progress)"
                          : ""}
                      </td>
                      <td className="px-1">
                        <button
                          className="btn px-1 py-0 btn-link"
                          onClick={() => handleEdit(row)}
                        >
                          <i className="fa fa-edit"></i>{" "}
                        </button>
                        {/* <button
                    className="btn px-1 py-0 btn-link text-danger"
                    onClick={(e) => handleInterestPricingInfoDelete(e, item.id)}
                  >
                    <i className="fa fa-trash"></i>{" "}
                  </button> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div
                style={{
                  color: "red",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "40vh",
                  fontSize: "20px",
                }}
              >
                No Education info to show
              </div>
            )}
          </>
        )}
      </Card>

      <EducationInfoEditModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        selectedEditItem={selectedEditItem}
        refetch={refetch}
        memberDateOfBirth={memberDateOfBirth}
        userId={userId}
      />
    </div>
  );
}
