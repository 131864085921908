import React from "react";
import VoucherForm from "./VoucherForm";
import { Card } from "reactstrap";

const CreateVoucherContain = () => {
  return (
    <div>
      <h3 style={{ color: "#2a6a7a", fontSize: "22px" }}>Create Voucher</h3>

      <VoucherForm />
    </div>
  );
};

export default CreateVoucherContain;
