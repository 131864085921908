import { createContext, useContext, useMemo, useState } from "react";

export const MemberDetails = createContext();

export const useMemberDetailsContext = () => {
  const context = useContext(MemberDetails);
  if (!context) {
    throw new Error("useContext must be used within a MemberDetailsProvider");
  }
  return context;
};

export const MemberDetailsProvider = ({ children }) => {
  const [memberList, setMemberList] = useState([]);
  const [memberDetails, setMemberDetails] = useState({});
  const [memberDateOfBirth, setMemberDateOfBirth] = useState(
    new Date("01/01/2000")
  );
  const [courseList, setCourseList] = useState([]);
  const [degreeList, setDegreeList] = useState([]);

  const contextValue = useMemo(
    () => ({
      memberList,
      setMemberList,
      memberDetails,
      setMemberDetails,
      memberDateOfBirth,
      setMemberDateOfBirth,
      courseList,
      setCourseList,
      degreeList,
      setDegreeList,
    }),
    [
      memberList,
      setMemberList,
      memberDetails,
      setMemberDetails,
      memberDateOfBirth,
      setMemberDateOfBirth,
      courseList,
      setCourseList,
      degreeList,
      setDegreeList,
    ]
  );

  return (
    <MemberDetails.Provider value={contextValue}>
      {children}
    </MemberDetails.Provider>
  );
};
