import React from "react";
import dashgboardImage from "../../assets/images/dashboard/dashboard4.avif";

const DashboardContain = () => {
  return (
    <div>
      <h3> Admin Dashboard</h3>
      <img style={{ width: "100%", height: "75vh" }} src={dashgboardImage} />
    </div>
  );
};

export default DashboardContain;
