import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { MemberDetailsGetApi } from "../api";
import { useMemberDetailsContext } from "../shared/_helper/MemberDetails/MemberDetailsProvider";

const getSingleMemberDetails = async ({ memberId }) => {
  const userData = JSON.parse(localStorage.getItem("adminData"));
  const localStorageToken = userData.token;
  const response = await axios.get(`${MemberDetailsGetApi}${memberId}`, {
    headers: {
      Authorization: `Bearer ${localStorageToken}`,
    },
  });
  return response;
};

export const useMemberDetailsInfo = (memberId) => {
  const { setMemberDetails, memberDateOfBirth, setMemberDateOfBirth } =
    useMemberDetailsContext();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["member-details", memberId],
    queryFn: () => getSingleMemberDetails({ memberId }),

    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setMemberDetails(data?.data);
      setMemberDateOfBirth(new Date(data?.data?.doB || "01/01/2000"));
      return data;
    },
    onError: (err) => {
      console.log("useMemberDetailsInfo error", err);
      return err;
    },
  });

  return { data, isLoading, refetch };
};
