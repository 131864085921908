import React, { useEffect, useState } from "react";
import {
    Table,
    Card,
    Pagination,
    PaginationItem,
    PaginationLink,
    Spinner,
} from "reactstrap";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import { useMemberDetailsContext } from "../../shared/_helper/MemberDetails/MemberDetailsProvider";
import { useMemberList } from "../../hooks/useMemberList";
import CommonPagination from "../common/Pagination/CommonPagination";

export default function InvoiceList() {
    const { memberList, setMemberList } = useMemberDetailsContext();
    const [selectedMemberType, setSelectedMemberType] = useState("all");
    const { data, isLoading } = useMemberList(selectedMemberType);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 12;

    const memberTypes = [
        { value: "all", label: "All" },
        { value: "tutor", label: "Tutor" },
        { value: "student", label: "Student" },
        { value: "both", label: "Both" },
    ];

    const handleSortByChange = (event) => {
        setSelectedMemberType(event.target.value);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const paginationData = memberList?.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const pageNumbers = [];
    for (
        let i = Math.max(1, currentPage - 2);
        i <=
        Math.min(Math.ceil(memberList?.length / itemsPerPage), currentPage + 2);
        i++
    ) {
        pageNumbers.push(i);
    }

    return (
        <div>
            <Card style={{ minHeight: "100vh", padding: "11px" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <h3 style={{ color: "#2a6a7a", fontSize: "22px" }}>Invoice List</h3>
                    </div>

                    <div>
                        <label
                            style={{
                                fontSize: "15px",
                                fontWeight: "600",
                                paddingRight: "10px",
                                color: "#2a6a7a",
                            }}
                        >
                            Invoices:
                        </label>
                        <select
                            onChange={handleSortByChange}
                            value={selectedMemberType}
                            style={{ width: "100px", height: "40px" }}
                        >
                            {memberTypes?.map((option) => (
                                <option
                                    key={option.value}
                                    value={option.value}
                                    style={{ textAlign: "center" }}
                                >
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div>
                    <div>
                        {memberList?.length} {memberList?.length > 1 ? "Members" : "Member"}{" "}
                        Found
                    </div>
                </div>
                {isLoading ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "10px",
                            minHeight: "50vh",
                        }}
                    >
                        <Spinner type="grow" color="primary" />
                    </div>
                ) : (
                    <Table
                        style={{
                            background: "#ffffff",
                            boxShadow: "0px 0px white",
                            borderRadius: "0px",
                            border: "0px",
                        }}
                    >
                        <thead>
                            <tr style={{ borderBottom: "3px solid black" }}>
                                <th>SL</th>
                                <th>Full Name</th>
                                <th>Email</th>
                                <th>Member Type</th>
                                <th>Gender</th>
                                <th>Joining Date</th>
                            </tr>
                        </thead>
                        {memberList && memberList?.length > 0 ? (
                            <tbody style={{ fontWeight: "500" }}>
                                {paginationData?.map((item, index) => (
                                    <tr
                                        key={index}
                                        style={{ borderBottom: "2px solid lightgrey" }}
                                    >
                                        <td>{indexOfFirstItem + index + 1}</td>

                                        <td>
                                            <Link to={`/member-details/${item?.userId}`}>
                                                {item?.name?.firstName ? item?.name?.firstName : ""}{" "}
                                                {item?.name?.lastName ? item?.name?.lastName : ""}
                                            </Link>
                                        </td>

                                        <td className="fw-normal">
                                            {item?.email ? item?.email : ""}
                                        </td>
                                        <td className="fw-normal">
                                            {item?.memberType === "tutor"
                                                ? "Tutor"
                                                : item?.memberType === "student"
                                                    ? "Student"
                                                    : item?.memberType === "both"
                                                        ? "Both"
                                                        : ""}
                                        </td>
                                        <td className="fw-normal">
                                            {item?.gender === "male"
                                                ? "Male"
                                                : item?.gender === "female"
                                                    ? "Female"
                                                    : item?.gender === "Mr."
                                                        ? "Male"
                                                        : "Other"}
                                        </td>
                                        <td className="fw-normal">
                                            {moment(new Date(item?.createdAt)).format("ll")}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="6" style={{ textAlign: "center" }}>
                                        <h3 style={{ color: "#ff8819", marginTop: "50px" }}>
                                            No member to show
                                        </h3>
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                )}

                <CommonPagination
                    currentPage={currentPage}
                    totalPages={Math.ceil(memberList.length / itemsPerPage)}
                    paginate={setCurrentPage}
                />
            </Card>
        </div>
    );
}
