import React from "react";
import {
    Container,
    Card,
    CardBody,
    Row,
    Col,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    // ListUnstyled,
    Badge,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
} from "reactstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPrint, faFilePdf, faPhoneAlt, faCircle } from "@fortawesome/free-solid-svg-icons";
// import { fab } from "@fortawesome/free-brands-svg-icons";
// import { library } from "@fortawesome/fontawesome-svg-core";
import logo from "../../assets/img/tuteair-logo.v2.svg";
import { Image } from "../../shared/AbstractElements";


export default function InvoiceDetails() {

    return (
        <Container className="py-5">
            <Card className="p-4">
                <CardBody>
                    <Container className="mb-2 mt-3">
                        <Row className="d-flex align-items-baseline">
                            <Col xl="9">
                                <p style={{ color: "#7e8d9f", fontSize: "20px" }}>
                                    Invoice &gt; &gt; <strong>ID: #123-123</strong>
                                </p>
                            </Col>
                            <Col xl="3" className="float-end">
                                <div className="d-flex justify-content-center gap-3">
                                    <Button>Print</Button>
                                    <Button>Export</Button>
                                </div>
                            </Col>
                        </Row>
                        <hr />
                    </Container>
                    <Container>
                        <Col className='d-flex justify-content-center align-items-center gap-2 pe-5' md="12">
                            <img style={{ width: '60px' }} className="" src={logo}></img>
                            <p className="h3">TuteAir</p>
                        </Col>
                    </Container>
                    <Container>
                        <Row>
                            <Col xl="8">
                                {/* <ListUnstyled> */}
                                <ul>
                                    <li className="text-muted">
                                        To: <span style={{ color: "#5d9fc5" }}>John Lorem</span>
                                    </li>
                                    <li className="text-muted">Street, City</li>
                                    <li className="text-muted">State, Country</li>
                                    <li className="text-muted">

                                        {/* <FontAwesomeIcon icon={faPhoneAlt} /> */}
                                        123-456-789
                                    </li>
                                </ul>
                                {/* </ListUnstyled> */}
                            </Col>
                            <Col xl="4">
                                <p className="h3">Invoice</p>
                                {/* <ListUnstyled> */}
                                <ul>
                                    <li className="text-muted">
                                        {/* <FontAwesomeIcon icon={faCircle} style={{ color: "#84B0CA" }} /> */}
                                        <span className="fw-bold ms-1">ID:</span>#123-456
                                    </li>
                                    <li className="text-muted">
                                        {/* <FontAwesomeIcon icon={faCircle} style={{ color: "#84B0CA" }} /> */}
                                        <span className="fw-bold ms-1">Creation Date: </span>Jun
                                        23,2021
                                    </li>
                                    <li className="text-muted">
                                        {/* <FontAwesomeIcon icon={faCircle} style={{ color: "#84B0CA" }} /> */}
                                        <span className="fw-bold ms-1">Status:</span>
                                        <Badge bg="warning" text="dark" fw="bold" className="ms-1">
                                            Paid
                                        </Badge>
                                    </li>
                                </ul>
                                {/* </ListUnstyled> */}
                            </Col>
                        </Row>
                    </Container>
                    <Container className="mt-4">
                        <Table
                            bordered
                            // responsive
                            striped
                            hover={false}
                            style={{
                                background: "#ffffff",
                                boxShadow: "0px 0px white",
                                borderRadius: "0px",
                                border: "0px",
                            }}
                        >
                            <thead>
                                <tr>
                                    <th>
                                        #
                                    </th>
                                    <th>
                                        Subject
                                    </th>
                                    <th>
                                        Class No.
                                    </th>
                                    <th>
                                        Tuition Fee
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">
                                        1
                                    </th>
                                    <td>
                                        Math
                                    </td>
                                    <td>
                                        4
                                    </td>
                                    <td>
                                        $10
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                        2
                                    </th>
                                    <td>
                                        English
                                    </td>
                                    <td>
                                        9
                                    </td>
                                    <td>
                                        $20
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                        3
                                    </th>
                                    <td>
                                        Bengali
                                    </td>
                                    <td>
                                        5
                                    </td>
                                    <td>
                                        $30
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <div className="d-flex justify-content-end mt-3">
                            <p className="h3">Total: $60</p>
                        </div>
                    </Container>
                    <hr />
                    <div className="text-center">
                        <p>Thank you for staying with TuteAir</p>
                    </div>
                </CardBody>
            </Card>
        </Container >
    );
}
