import React, { useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import TutorInterestedToTech from "./TutorInterestedToTech";
import StudentInterestedToLearn from "./StudentInterestedToLearn";

const InterestedSubjectTabs = ({ memberDetails, refetch, isLoading }) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <Nav pills style={{ display: "flex", justifyContent: "center" }}>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggle("1");
            }}
            style={{
              cursor: "pointer",
              background: activeTab === "1" ? "#ff8819" : "",
            }}
          >
            Tutor Interested to Teach
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggle("2");
            }}
            style={{
              cursor: "pointer",
              background: activeTab === "2" ? "#ff8819" : "",
            }}
          >
            Student Interested to Learn
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab} className="mt-10">
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <TutorInterestedToTech
                interestAndPricing={memberDetails?.interestAndPricing}
                refetch={refetch}
                isLoading={isLoading}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col sm="12">
              <StudentInterestedToLearn
                interestToLearn={memberDetails?.interestToLearn}
                refetch={refetch}
                isLoading={isLoading}
              />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default InterestedSubjectTabs;
