import { Fragment } from "react";
import Routers from "./constants/Routes";
import AnimationThemeProvider from "./shared/_helper/AnimationTheme/AnimationThemeProvider";
import FilterProvider from "./shared/_helper/Filter/FilterProvider";
import { default as CustomizerProvider } from "./shared/_helper/customizer/CustomizerProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MemberDetailsProvider } from "./shared/_helper/MemberDetails/MemberDetailsProvider";
import QueryProviders from "./utils/lists/QueryProviders";

function App() {
  const authChecked = true; //useAuthCheck();
  return !authChecked ? (
    <div>
      {
        //"Checking authentication...."
      }
    </div>
  ) : (
    <Fragment>
      <QueryProviders>
        <CustomizerProvider>
          <FilterProvider>
            <AnimationThemeProvider>
              <MemberDetailsProvider>
                <Routers />
              </MemberDetailsProvider>
            </AnimationThemeProvider>
          </FilterProvider>
        </CustomizerProvider>
      </QueryProviders>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Fragment>
  );
}
export default App;
