import React, { useState, useEffect } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import axios from "axios";
import { QueryBuilder } from "react-querybuilder";
import { fields } from "../CreateVoucher/fields";
import "react-querybuilder/dist/query-builder.scss";
import "../CreateVoucher/VoucherForm.css";
import { VoucherUpdateApi } from "../../api/index";
import allowedOperatorsList from "../CreateVoucher/operators";
import { toastNotify } from "../../Services/ToastNotify";

const VoucherEditForm = ({ refetch,voucher }) => {
  const userData = JSON.parse(localStorage.getItem("adminData"));
  const localStorageToken = userData.token;
  const [isFormDirty, setIsFormDirty] = useState(false);

  const [voucherDetails, setVoucherDetails] = useState({
    voucherCode: voucher.code,
    createdBy: userData.firstName + " " + userData.lastName,
    expiresAt: voucher.expiresAt,
    maxUsageCount: voucher.maxUsageCount,
    usageCount: voucher.usageCount,
    discountType: voucher.discountType,
    discountAmount: voucher.discountAmount,
    maxDiscountAmount: voucher.maxDiscountAmount,
    rules: voucher.rules,
  });
  const [query, setQuery] = useState({
    combinator: "and",
    rules: voucherDetails.rules,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVoucherDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    setIsFormDirty(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormDirty) {
      // No meaningful changes, return early
      return;
    }

    try {
      const formData = {
        ...voucherDetails,
        rules: query.rules,
        adminId: userData._id,
      };

      const res = await axios.put(
        VoucherUpdateApi + `/${voucher._id}`,
        formData,
        {
          headers: {
            authorization: `Bearer ${localStorageToken}`,
          },
        }
      );

      if (res?.status === 200) {
        toastNotify(res?.data?.message, "success");
        setIsFormDirty(false);
        refetch();
      }
    } catch (error) {
      console.log('error',error);
      // toastNotify(
      //   "Failed to update the voucher. Please verify the inputs and try again.",
      //   "danger"
      // );
    }
  };

  // Function to format the date as "yyyy-MM-dd"
  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <Form className="voucher-form" onSubmit={handleSubmit}>
      {/* Voucher details inputs */}
      <div className="voucher-details">
        <h2>Voucher Details</h2>
        <FormGroup>
          <Label for="voucherCode">Voucher Code:</Label>
          <Input
            type="text"
            id="voucherCode"
            name="voucherCode"
            disabled
            value={voucherDetails.voucherCode}
            onChange={handleInputChange}
          />
        </FormGroup>

        {/* Discount Type */}
        <FormGroup>
          <Label for="discountType">Discount Type:</Label>
          <Input
            type="select"
            id="discountType"
            name="discountType"
            value={voucherDetails.discountType}
            onChange={handleInputChange}
          >
            <option value="fixed">Fixed Amount</option>
            <option value="percentage">Percentage</option>
          </Input>
        </FormGroup>

        {/* Discount Amount */}
        {voucherDetails.discountType === "percentage" ? (
          <>
            <FormGroup>
              <Label for="discountAmount">Discount Percentage:</Label>
              <Input
                type="text"
                id="discountAmount"
                name="discountAmount"
                value={voucherDetails.discountAmount}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="maxDiscountAmount">Max Discount Amount:</Label>
              <Input
                type="text"
                id="maxDiscountAmount"
                name="maxDiscountAmount"
                value={voucherDetails.maxDiscountAmount}
                onChange={handleInputChange}
              />
            </FormGroup>
          </>
        ) : (
          <FormGroup>
            <Label for="discountAmount">Discount Amount:</Label>
            <Input
              type="text"
              id="discountAmount"
              name="discountAmount"
              value={voucherDetails.discountAmount}
              onChange={handleInputChange}
            />
          </FormGroup>
        )}

        <FormGroup>
          <Label for="expiresAt">Expiration Date:</Label>
          <Input
            type="date"
            id="expiresAt"
            name="expiresAt"
            // value={voucherDetails.expiresAt}
            value={formatDate(voucherDetails.expiresAt)}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="maxUsageCount">Max Usage Count:</Label>
          <Input
            type="number"
            id="maxUsageCount"
            name="maxUsageCount"
            min="1"
            value={voucherDetails.maxUsageCount}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="usageCount">Usage Count:</Label>
          <Input
            type="number"
            id="usageCount"
            name="usageCount"
            min="0"
            disabled
            value={voucherDetails.usageCount}
            onChange={handleInputChange}
          />
        </FormGroup>
      </div>

      {/* Dynamic rules section */}
      <div className="rules-section">
        <h2>Rules</h2>
        <div>
          <QueryBuilder
            fields={fields}
            query={query}
            enableMountQueryChange={false}
            operators={allowedOperatorsList}
            //onQueryChange={(q) => setQuery(q)}
            onQueryChange={(q) => {
              setQuery(q);
              setIsFormDirty(true);
            }}
            controlElements={{
              addGroupAction: () => null,
              combinatorSelector: () => null,
            }}
          />
        </div>
      </div>

      {/* Submit button */}
      <Button type="submit" disabled={!isFormDirty}>
        Update Voucher
      </Button>
    </Form>
  );
};

export default VoucherEditForm;
