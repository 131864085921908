// import LandingHomeContain from "../Component/Home/index";

import LoginPage from "../../pages/auth/LoginPage";

export const authRoutes = [
  {
    path: `${process.env.PUBLIC_URL}/sign-in`,
    Component: <LoginPage />,
  },
];
