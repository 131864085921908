import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import Multiselect from "multiselect-react-dropdown";
import {
  getAllSubjects,
  editInterestedToLearnInfo,
} from "../../../common/InterestedSubjectsFn";
import axios from "axios";
import { AllDegreeLevels } from "../../../../api";
import { toast } from "react-toastify";
import { useNavigate, useParams, Link } from "react-router-dom";
import SweetAlert from "sweetalert2";

function InterestedToLearnEdit({
  modalOpen,
  setModalOpen,
  selectedEditItem,
  refetch,
}) {
  const params = useParams();
  const userId = params.id;
  const toggle = () => setModalOpen(!modalOpen);

  const [subjects, setSubjects] = useState([]);
  const [tuitionType, setTuitionType] = useState([]);
  const [classList, setClassList] = useState([]);
  const [classes, setClasses] = useState({});
  const [amount, setAmount] = useState("");
  const [classesName, setClassesName] = useState("");
  const [interestToLearnId, setInterestedToLearnId] = useState("");
  const [allSubjects, setAllSubjects] = useState([]);

  const [tutionTypeList, setTutionTypeList] = useState([
    "Online",
    "Teachers Place",
    "Students Place",
    "Public Place",
  ]);

  const [selectedTutionTypeOptions, setTutionTypeSelectedOptions] = useState(
    []
  );

  useEffect(() => {
    if (selectedEditItem) {
      setSubjects(selectedEditItem?.subjects);
      setTuitionType(selectedEditItem?.tutionTypes);
      setTutionTypeSelectedOptions(selectedEditItem?.tutionTypes);

      const desClasses = {
        value: selectedEditItem?.classes?.id,
        label: selectedEditItem?.classes?.name,
      };
      setClasses(desClasses);
      setClassesName(selectedEditItem?.classes?.name);
      setAmount(selectedEditItem?.amount?.initialPrice);
      setInterestedToLearnId(selectedEditItem?.id);
    }
  }, [selectedEditItem]);

  useEffect(() => {
    fetchSubjects();
  }, []);

  const fetchSubjects = async () => {
    const res = await getAllSubjects();

    if (res && res?.data) {
      setAllSubjects(res?.data);
    }
  };

  const handleChangeLevel = (event) => {
    const value = event.target.value;
    const cs = classList?.find((item) => item.label === value);
    setClasses(cs);
    setClassesName(cs.label);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 330,
      },
    },
  };

  useEffect(() => {
    fetchAllDegreeLevels();
  }, []);

  const fetchAllDegreeLevels = async () => {
    const userData = JSON?.parse(localStorage.getItem("adminData"));
    const localStorageToken = userData?.token;
    const res = await axios.get(AllDegreeLevels, {
      headers: {
        authorization: `Bearer ${localStorageToken}`,
      },
    });

    var classList1 = [];
    if (res.data.data) {
      res.data.data.map((item, index) => {
        classList1.push({
          value: item._id,
          label: item.name,
        });
      });
    }
    setClassList(mySortFunction(classList1));
  };

  function mySortFunction(list) {
    var sortedList = list
      ?.slice()
      ?.sort((a, b) => a.label.localeCompare(b.label));
    return sortedList;
  }

  const handleInterestedToLearnEdit = () => {
    var htmlContent = ` </strong> <h5 class='pt-1'>Are you sure to update interested to learn?</h5>`;

    SweetAlert.fire({
      title: "Update Interested to Learn",
      html: htmlContent,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        await editInterestToLearn();
      }
    });
  };

  const editInterestToLearn = async () => {
    var data = {
      subjects,
      classes,
      specializeSubjects: [],
      amount,
      isNegotiable: true,
      tutionTypes: tuitionType,
      interestToLearnId,
      userId,
    };

    const res = await editInterestedToLearnInfo(data);

    if (res) {
      if (res.status === 201) {
        toast.success("Successfully updated interested to Learn!");
        toggle();
        refetch();
      } else {
        toast.error("Failed to update!!");
      }
    } else {
      toast.error("Failed update");
    }
  };

  return (
    <div>
      <Modal isOpen={modalOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          <h3
            style={{
              color: "#2a6a7a",
            }}
          >
            Update Student Interested To Learn
          </h3>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={12}>
              <FormGroup className="w-full md:w-80">
                <Label for="level">Subject</Label>
                <Multiselect
                  isObject={true}
                  options={allSubjects}
                  displayValue="name"
                  showCheckbox
                  onRemove={(e) => {
                    setSubjects(e);
                  }}
                  onSelect={(e) => {
                    setSubjects(e);
                  }}
                  selectedValues={subjects}
                />

                {/* Render options here */}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup className="w-full md:w-80">
                <Label for="tuitionType">Tuition Type</Label>
                <Multiselect
                  className="darkMode"
                  isObject={false}
                  options={tutionTypeList}
                  showCheckbox
                  onRemove={(e) => {
                    setTuitionType(e);
                  }}
                  onSelect={(e) => {
                    setTuitionType(e);
                  }}
                  selectedValues={selectedTutionTypeOptions}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup className="w-full md:w-80">
                <Label for="level">Level</Label>
                <Input
                  type="select"
                  name="level"
                  id="level"
                  value={classesName}
                  onChange={handleChangeLevel}
                >
                  {classList.map((name) => (
                    <option key={name.value} value={name.label}>
                      {name.label}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col md={5} display="flex">
              <FormGroup>
                <Label for="tuitionTitle">Amount</Label>
                <span style={{ display: "flex", alignItems: "baseline" }}>
                  <Input
                    type="text"
                    name="amount"
                    id="amount"
                    value={amount}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const matchedValue = inputValue
                        ?.replace(/[^\d.]/g, "")
                        ?.match(/^(?!0\d)\d{0,6}(\.\d{0,2})?/);

                      if (
                        inputValue === "" ||
                        (matchedValue !== null && matchedValue[0] === "")
                      ) {
                        setAmount("");
                      } else if (matchedValue !== null) {
                        const parsedValue = parseFloat(matchedValue[0]);
                        if (
                          !isNaN(parsedValue) &&
                          parsedValue >= 0 &&
                          parsedValue <= 1000000
                        ) {
                          setAmount(parsedValue.toString());
                        }
                      }
                    }}
                  />
                  <p style={{ marginLeft: "8px" }}>USD/class</p>
                </span>
                <p style={{ color: "#fb923d", fontSize: "15px" }}>
                  *20% of the income will go to TuteAir
                </p>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            style={{ fontFamily: "sans-serif" }}
            color="secondary"
            onClick={toggle}
          >
            Cancel
          </Button>
          <Button
            style={{ fontFamily: "sans-serif" }}
            color="primary"
            onClick={() => handleInterestedToLearnEdit()}
          >
            Update
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default InterestedToLearnEdit;
