import React, { Fragment, useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
// import { ProfilePictureRootURL } from "../../../api";
import man from "../../assets/images/profile-image/profile-image.png";
import {
  ProfilePictureRootURL,
  TutorDetailsGetApi,
  TutorPayableAmountGetApi,
  TutorPayoutDoneUpdateApi,
  TutorPayoutGetApi,
} from "../../api";
import { toast } from "react-toastify";

const TutorPaymentInfoContain = () => {
  const params = useParams();
  const tutorId = params.id;
  const userData = JSON.parse(localStorage.getItem("adminData"));
  const localStorageToken = userData.token;
  const [tutorDetails, setTutorDetails] = useState("");
  const [tutorPayableAmountDetails, setTutorPayableAmountDetails] =
    useState("");
  const [payableAmount, setPayableAmount] = useState("");
  const [tutorPayoutMethod, setTutorPayoutMethod] = useState("");
  const [tutorPayoutDetails, setTutorPayoutDetails] = useState("");
  const [payoutBtnDisable, setPayoutBtnDisable] = useState(true);

  //----------------for tutors details-------------------
  useEffect(async () => {
    try {
      const res = await axios.post(
        TutorDetailsGetApi,
        {
          id: tutorId,
        },
        {
          headers: {
            authorization: `Bearer ${localStorageToken}`,
          },
        }
      );
      if (res) {
        setTutorDetails(res.data);
      }
    } catch (error) {
      console.error(error);
      setTutorDetails("");
    }
    return () => setTutorDetails("");
  }, [tutorId, localStorageToken]);


  //--------for tutor's payable amount details---------------
  useEffect(async () => {
    try {
      const res = await axios.post(
        TutorPayableAmountGetApi,
        {
          id: tutorId,
        },
        {
          headers: {
            authorization: `Bearer ${localStorageToken}`,
          },
        }
      );
      if (res) {
        // console.log(res.data);
        setTutorPayableAmountDetails(res.data);
      }
    } catch (error) {
      console.error(error);
      setTutorPayableAmountDetails("");
    }

    return () => setTutorPayableAmountDetails("");
  }, [tutorId, localStorageToken]);

  //--------for tutor's payout details---------------
  useEffect(async () => {
    try {
      const res = await axios.post(
        TutorPayoutGetApi,
        {
          id: tutorId,
        },
        {
          headers: {
            authorization: `Bearer ${localStorageToken}`,
          },
        }
      );
      if (res) {
        console.log(res.data.info);
        setTutorPayoutMethod(res?.data?.method);
        setTutorPayoutDetails(res?.data?.info);
      }
    } catch (error) {
      console.error(error);
      setTutorPayoutMethod("");
      setTutorPayoutDetails("");
    }

    return () => {
      setTutorPayoutMethod("");
      setTutorPayoutDetails("");
    };
  }, [tutorId, localStorageToken]);

  //-------for resetting other payout methods---------
  useEffect(() => {
    if (tutorPayoutMethod) {
      if (tutorPayoutMethod.includes("others")) {
        const paymentMethod = tutorPayoutMethod.split("/")[1];
        // console.log(paymentMethod);
        setTutorPayoutMethod(paymentMethod);
      }
    }
  }, [tutorPayoutMethod]);

  //-------for resetting other payable amount---------
  useEffect(() => {
    if (tutorPayableAmountDetails) {
      setPayableAmount(tutorPayableAmountDetails?.payableAmount);
    }
  }, [tutorPayableAmountDetails]);

  //--disabling payout done button until payableAmountDetails arrives---
  useEffect(() => {
    if (tutorPayableAmountDetails && tutorPayoutDetails && tutorPayoutMethod) {
      if (tutorPayableAmountDetails.payableAmount > 0) {
        setPayoutBtnDisable(false);
      } else { setPayoutBtnDisable(true) };
    } else { setPayoutBtnDisable(true) };
  }, [tutorPayableAmountDetails, tutorPayoutDetails, tutorPayoutMethod]);

  //---------after manually paying out the amount--------
  const handlePay = async (e) => {
    e.preventDefault();
    const confirmation = prompt(
      "Type 'CONFIRM' in capital letters then click OK to confirm payout is done"
    );
    if (confirmation !== "CONFIRM") {
      toast.error("Payout not done");
      return;
    }

    try {
      const res = await axios.put(
        TutorPayoutDoneUpdateApi,
        {
          id: tutorId,
          payableAmount: tutorPayableAmountDetails?.payableAmount,
          payoutDetails: {
            tutorPayoutMethod,
            tutorPayoutDetails
          }
        },
        {
          headers: {
            authorization: `Bearer ${localStorageToken}`,
          },
        }
      );
      console.log(res.data);
      if (res) {
        if (res.data.acknowledged) {
          toast.success("Payout done successfully");
          setPayableAmount("0");
          setPayoutBtnDisable(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Fragment>
      <div>
        <Card>
          <CardBody className="d-flex align-items-center">
            <Row className="col-12">
              <Col sm="1">
                <img
                  alt="student profile"
                  style={{ width: "112px", height: "112px" }}
                  className="rounded-circle  img-fluid m-r-20 "
                  // src={authenticated ? (profile2 ? profile2 : profile) : profile}
                  src={
                    tutorDetails
                      ? ProfilePictureRootURL + tutorDetails?.profilePicture
                      : man
                  }
                />
              </Col>

              <Col sm="6" className="d-flex align-items-center mt-3">
                <div style={{ flex: "auto" }}>
                  <CardTitle tag="h3" className="cardBox-2">
                    <span>
                      {tutorDetails ? tutorDetails?.firstName : undefined}{" "}
                    </span>
                    <span>
                      {tutorDetails ? tutorDetails?.lastName : undefined}{" "}
                    </span>
                  </CardTitle>
                  <CardTitle tag="h5" className="cardBox-2">
                    <span>
                      {tutorDetails ? tutorDetails?.email : undefined}{" "}
                    </span>
                  </CardTitle>
                  {/* <CardTitle tag="h5" className="cardBox-2">
                    Lives in Dhaka, Bangladesh
                  </CardTitle> */}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Row className="d-flex justify-content-around">
          <Col sm="6">
            <h3 className="mb-3">Balance Information</h3>
            <Form>
              <FormGroup row>
                <Label for="exampleEmail2" sm={4}>
                  Payable Amount
                </Label>

                <Col sm={4}>
                  <Input
                    disabled
                    id="exampleEmail2"
                    name="email"
                    placeholder="default"
                    type="email"
                    value={payableAmount ? payableAmount : "loading"}
                  />
                </Col>
              </FormGroup>
            </Form>
          </Col>
          <Col sm="6">
            <div>
              <h3 className="mb-3">Payout Information</h3>
              <Form>
                <FormGroup row>
                  <Label for="exampleEmail2" sm={4}>
                    Payout Method
                  </Label>

                  <Col sm={8}>
                    <Input
                      disabled
                      id="exampleEmail2"
                      name="email"
                      placeholder="default"
                      type="email"
                      value={
                        tutorPayoutMethod ? tutorPayoutMethod : "Not provided"
                      }
                    />
                  </Col>
                </FormGroup>
                {tutorPayoutDetails?.bankName && (
                  <FormGroup row>
                    <Label for="exampleEmail2" sm={4}>
                      Bank Name
                    </Label>
                    <Col sm={8}>
                      <Input
                        disabled
                        id="exampleEmail2"
                        name="email"
                        type="email"
                        value={tutorPayoutDetails?.bankName}
                      />
                    </Col>
                  </FormGroup>
                )}
                {tutorPayoutDetails?.accountName && (
                  <FormGroup row>
                    <Label for="exampleEmail2" sm={4}>
                      Account Name
                    </Label>
                    <Col sm={8}>
                      <Input
                        disabled
                        id="exampleEmail2"
                        name="email"
                        type="email"
                        value={tutorPayoutDetails?.accountName}
                      />
                    </Col>
                  </FormGroup>
                )}
                {tutorPayoutDetails?.accountNumber && (
                  <FormGroup row>
                    <Label for="exampleEmail2" sm={4}>
                      Account Number
                    </Label>
                    <Col sm={8}>
                      <Input
                        disabled
                        id="exampleEmail2"
                        name="email"
                        type="email"
                        value={tutorPayoutDetails?.accountNumber}
                      />
                    </Col>
                  </FormGroup>
                )}

                {tutorPayoutDetails?.branchName && (
                  <FormGroup row>
                    <Label for="exampleEmail2" sm={4}>
                      Branch Name
                    </Label>
                    <Col sm={8}>
                      <Input
                        disabled
                        id="exampleEmail2"
                        name="email"
                        type="email"
                        value={tutorPayoutDetails?.branchName}
                      />
                    </Col>
                  </FormGroup>
                )}
                {tutorPayoutDetails?.routingNumber && (
                  <FormGroup row>
                    <Label for="exampleEmail2" sm={4}>
                      Routing Number
                    </Label>
                    <Col sm={8}>
                      <Input
                        disabled
                        id="exampleEmail2"
                        name="email"
                        type="email"
                        value={tutorPayoutDetails?.routingNumber}
                      />
                    </Col>
                  </FormGroup>
                )}
                {tutorPayoutDetails?.swiftCode && (
                  <FormGroup row>
                    <Label for="exampleEmail2" sm={4}>
                      Swift Code
                    </Label>
                    <Col sm={8}>
                      <Input
                        disabled
                        id="exampleEmail2"
                        name="email"
                        type="email"
                        value={tutorPayoutDetails?.swiftCode}
                      />
                    </Col>
                  </FormGroup>
                )}
                {tutorPayoutDetails?.email && (
                  <FormGroup row>
                    <Label for="exampleEmail2" sm={4}>
                      Account Email
                    </Label>
                    <Col sm={8}>
                      <Input
                        disabled
                        id="exampleEmail2"
                        name="email"
                        type="email"
                        value={tutorPayoutDetails?.email}
                      />
                    </Col>
                  </FormGroup>
                )}
                {tutorPayoutDetails?.phone && (
                  <FormGroup row>
                    <Label for="exampleEmail2" sm={4}>
                      Account Phone Number
                    </Label>
                    <Col sm={8}>
                      <Input
                        disabled
                        id="exampleEmail2"
                        name="email"
                        type="email"
                        value={tutorPayoutDetails?.phone}
                      />
                    </Col>
                  </FormGroup>
                )}
                {tutorPayoutDetails?.mobileNumber && (
                  <FormGroup row>
                    <Label for="exampleEmail2" sm={4}>
                      Account Mobile Number
                    </Label>
                    <Col sm={8}>
                      <Input
                        disabled
                        id="exampleEmail2"
                        name="email"
                        type="email"
                        value={tutorPayoutDetails?.mobileNumber}
                      />
                    </Col>
                  </FormGroup>
                )}
                {tutorPayoutDetails?.mobileNumberOrId && (
                  <FormGroup row>
                    <Label for="exampleEmail2" sm={4}>
                      Account Mobile Number / {tutorPayoutMethod} ID
                    </Label>
                    <Col sm={8}>
                      <Input
                        disabled
                        id="exampleEmail2"
                        name="email"
                        type="email"
                        value={tutorPayoutDetails?.mobileNumberOrId}
                      />
                    </Col>
                  </FormGroup>
                )}
                {tutorPayoutDetails?.referenceNumber && (
                  <FormGroup row>
                    <Label for="exampleEmail2" sm={4}>
                      Reference Number
                    </Label>
                    <Col sm={8}>
                      <Input
                        disabled
                        id="exampleEmail2"
                        name="email"
                        type="email"
                        value={tutorPayoutDetails?.referenceNumber}
                      />
                    </Col>
                  </FormGroup>
                )}
                <FormGroup row>
                  <Label for="exampleEmail2" sm={4}>
                    Total Payable Amount
                  </Label>
                  <Col sm={8}>
                    <Input
                      disabled
                      id="exampleEmail2"
                      name="email"
                      type="email"
                      value={payableAmount ? payableAmount : "loading"}
                    />
                  </Col>
                </FormGroup>

                <Link to="/tutor-list">
                  <Button
                    color="warning"
                    className="text-end"
                    style={{
                      fontSize: "16px",
                      position: "absolute",
                      right: "180px",
                      padding: "5px 22px",
                    }}
                  >
                    Back
                  </Button>
                </Link>

                <Button
                  disabled={payoutBtnDisable}
                  onClick={handlePay}
                  color="primary"
                  className="text-end"
                  style={{
                    fontSize: "16px",
                    position: "absolute",
                    right: "15px",
                    padding: "5px 34px",
                  }}
                >
                  Payout Done
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default TutorPaymentInfoContain;
