import React, { useState } from "react";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { saveSingleImageToCloudinary } from "../../../common/cloudinaryImageAttachments/CommonPhotoAttachmentFn";
import ImageUploader from "../../../common/HookFormFields/ImageUploader";
import { ProfilePictureRootURL } from "../../../../api";
import { SubmitHandler, useController, useForm } from "react-hook-form";

export default function ProfilePicture({ profileInfo, userId, userName }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const { control, handleSubmit, watch, formState } = useForm({
    shouldFocusError: false,
    mode: "onChange",
  });

  const handleImageConvertIntoCloudinary = async (imageUrl, userName) => {
    try {
      const dataURL = await fetchImageAsDataURL(imageUrl);
      const file = dataURLtoFile(dataURL, `${userName?.firstName}.jpeg`);
      uploadToCloudinary(file);
    } catch (error) {
      console.error("Error converting image:", error);
    }
  };

  const uploadToCloudinary = async (file) => {
    try {
      if (userId && file) {
        const { imageUrl } = await saveImageToCloudinary(file);
        saveImageUrlToDatabase(imageUrl);
      }
    } catch (error) {
      alert("Select image again");
    }
  };

  const saveImageToCloudinary = async (file) => {
    return saveSingleImageToCloudinary(file, `profile-image/${userId}`);
  };

  const saveImageUrlToDatabase = async (imageUrl) => {
    const value = {
      imageInfo: imageUrl?.imageInfo,
      storageProvider: imageUrl?.storageProvider,
    };
    const userData = JSON?.parse(localStorage.getItem("adminData"));
    const localStorageToken = userData?.token;

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ROOT_ENVIRONMENT}/api/admin/member-photo-update/${userId}`,
        value,
        {
          headers: {
            authorization: `Bearer ${localStorageToken}`,
          },
        }
      );

      if (res?.data && res.status === 200) {
        toast.success(res?.data?.data?.message, {
          position: "top-center",
        });
      }
    } catch (error) {
      console.error("Error saving image to database:", error);
    }
  };

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(",");
    const mime = "image/jpeg";
    const bstr = atob(arr[1]);
    const n = bstr.length;
    const u8arr = new Uint8Array(n);
    for (let i = 0; i < n; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const fetchImageAsDataURL = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error fetching image:", error);
      throw error;
    }
  };

  const onFileDrop = (e) => {
    const target = e.target;
    if (!target.files) return;
    const file = target.files[0];
    setSelectedFile(file);
    const imageUrl = URL.createObjectURL(file);
    setSelectedImage(imageUrl);
  };

  return (
    <div className="">
      <div className="">
        <div className=" ">
          <div className={`text-center`}>
            <img
              src={
                selectedImage ||
                profileInfo?.profilePicture?.imageInfo?.secure_url ||
                ProfilePictureRootURL + profileInfo?.picture
              }
              alt="Profile-picture"
              className="rounded-circle"
              style={{ width: "300px", height: "300px" }}
            ></img>
            <div className="">
              <ImageUploader
                control={control}
                registerName="picture"
                onChangeFn={onFileDrop}
                multiple={false}
                required={true}
              ></ImageUploader>
              {profileInfo?.profilePicture?.imageInfo?.secure_url ? (
                <Button
                  color="primary"
                  variant="contained"
                  className="mt-6"
                  onClick={() => uploadToCloudinary(selectedFile)}
                  disabled={selectedImage === null}
                >
                  Update
                </Button>
              ) : (
                <Button
                  color="primary"
                  onClick={() =>
                    handleImageConvertIntoCloudinary(
                      ProfilePictureRootURL + profileInfo?.picture,
                      userName
                    )
                  }
                  disabled={
                    (profileInfo?.picture === null ||
                      profileInfo?.picture === "") &&
                    selectedImage === null
                  }
                >
                  Save To Cloudinary
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
