import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import moment from "moment/moment";
import "./VoucherDetails.css"; // Import your custom CSS file for styling

const VoucherDetails = ({ isOpen, toggleModal, selectedVoucher }) => {
  const renderDiscountInfo = (voucher) => {
    if (voucher.discountType === "percentage") {
      return (
        <div className="voucher-card-property">
          <span>Discount Percentage:</span> {voucher.discountAmount}%
        </div>
      );
    } else if (voucher.discountType === "fixed") {
      return (
        <div className="voucher-card-property">
          <span>Discount Amount:</span> ${voucher.discountAmount}
        </div>
      );
    }
    return null;
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      className="voucher-details-modal"
    >
      <ModalHeader toggle={toggleModal}>Voucher Details</ModalHeader>
      <ModalBody>
        {selectedVoucher && (
          <div className="voucher-details-content">
            <div className="voucher-title">{selectedVoucher.code}</div>
            <div className="voucher-details">
              <div className="voucher-property">
                <span className="property-label">Created By:</span>{" "}
                {selectedVoucher.createdBy}
              </div>
              <div className="voucher-property">
                <span className="property-label">Used By:</span>{" "}
                {selectedVoucher.usedBy?.length} People
              </div>
              <div className="voucher-property">
                <span className="property-label">Expires At:</span>{" "}
                {moment(new Date(selectedVoucher.expiresAt)).format("ll")}
              </div>
              <div className="voucher-property">
                <span className="property-label">Max Usage Count:</span>{" "}
                {selectedVoucher.maxUsageCount}
              </div>
              <div className="voucher-property">
                <span className="property-label">Usage Count:</span>{" "}
                {selectedVoucher.usageCount}
              </div>
              <div className="voucher-property">
                <span className="property-label">Discount Type:</span>{" "}
                {selectedVoucher.discountType}
              </div>
              {renderDiscountInfo(selectedVoucher)}
              {selectedVoucher.maxDiscountAmount && (
                <div className="voucher-property">
                  <span className="property-label">Max Discount Amount:</span> $
                  {selectedVoucher.maxDiscountAmount}
                </div>
              )}
              <div className="voucher-property">
                <span className="property-label">Expired:</span>{" "}
                {selectedVoucher.isExpired.toString()}
              </div>
            </div>

            {/* Voucher Rules Section */}
            {selectedVoucher?.rules?.length > 0 && (
              <div className="voucher-section rules-section">
                <div className="voucher-section-title">Rules:</div>
                <ul className="rules-list">
                  {selectedVoucher?.rules.map((rule) => (
                    <li key={rule.id} className="rule-item">
                      {`${rule.field} ${rule.operator} ${rule.value}`}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* Last Updated By Section */}
            {selectedVoucher?.updatedBy?.length > 0 && (
              <div className="voucher-section updatedBy-section">
                <div className="voucher-section-title">Last Updated By:</div>
                <ul className="updated-users-list">
                  {selectedVoucher?.updatedBy.map((userData) => (
                    <li key={userData._id} className="updated-user-item">
                      <span className="updated-user-name">{userData.name}</span>
                      <span className="updated-user-timestamp">
                        At: {moment(userData.timestamp).format("lll")}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div className="voucher-property">
              <span className="property-label">Created At:</span>{" "}
              {moment(new Date(selectedVoucher.createdAt)).format("ll")}
            </div>
            <div className="voucher-property">
              <span className="property-label">Updated At:</span>{" "}
              {moment(new Date(selectedVoucher.updatedAt)).format("ll")}
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default VoucherDetails;
