import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { MemberListGetApi } from "../api";
import { useMemberDetailsContext } from "../shared/_helper/MemberDetails/MemberDetailsProvider";

const getAllMemberList = async ({ memebrType }) => {
  const userData = JSON.parse(localStorage.getItem("adminData"));
  const localStorageToken = userData.token;
  const response = await axios.get(`${MemberListGetApi}${memebrType}`, {
    headers: {
      Authorization: `Bearer ${localStorageToken}`,
    },
  });
  return response;
};

export const useMemberList = (memebrType) => {
  const { setMemberList } = useMemberDetailsContext();

  const { data, isLoading } = useQuery({
    queryKey: ["member-list", memebrType],
    queryFn: () => getAllMemberList({ memebrType }),

    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setMemberList(data?.data);
      return data;
    },
    onError: (err) => {
      console.log("useMemberList error", err);
      return err;
    },
  });

  return { data, isLoading };
};
