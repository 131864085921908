import React, { Fragment, useEffect } from "react";
import { LogIn, User } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import { Media } from "reactstrap";
import man from "../../../assets/images/avtar/man.png";
import { Image, LI, UL } from "../../../shared/AbstractElements";
// import man from "../../assets/images/avtar/man.png";
import MaxMiniSize from "./MaxMiniSize";
import Notification from "./Notification";
import { ProfilePictureRootURL } from "../../../api";

import { useContext } from "react";
import { toast } from "react-toastify";
// import FilterContext from "../../_helper/Filter";

const HeaderContain = () => {
  const auth0_profile = JSON.parse(localStorage.getItem("adminData"));
  // const { themeReminder, setMoonlight, setThemeReminder } =
  //   useContext(FilterContext);

  const navigate = useNavigate();
  const Logout = () => {
    localStorage.removeItem("adminData");
    toast.warning("Logged Out");
    navigate(`${process.env.PUBLIC_URL}/sign-in`);
  };

  //--- setting theme to previous preference
  // useEffect(() => {
  //   const theme = localStorage.getItem("theme_reminder");
  //   if (themeReminder) {
  //     setMoonlight(true);
  //     document.body.className = "dark-only";
  //     localStorage.setItem("layout_version", "dark-only");
  //   } else if (theme === "dark-only") {
  //     setMoonlight(true);
  //     setThemeReminder(true);
  //     document.body.className = "dark-only";
  //     localStorage.setItem("layout_version", "dark-only");
  //   }
  // }, []);

  return (
    <Fragment>
      <div className="nav-right col-10 col-sm-6 pull-right right-header p-0 dash-76">
        <UL
          attrUL={{ className: `simple-list flex-row nav-menus` }}
          className="d-flex justify-content-left align-items-center"
        >
          {/* <MoonLight /> */}
          {/* <Notification /> */}
          <MaxMiniSize />

          <LI
            attrLI={{
              className: "profile-nav onhover-dropdown pe-0 pt-0 me-0",
            }}
          >
            <Media className="profile-media">
              <Image
                attrImage={{
                  className: "img-40 img-fluid m-r-20 rounded-circle",
                  src: `${
                    auth0_profile.profilePicture &&
                    auth0_profile.profilePicture !== " "
                      ? ProfilePictureRootURL + auth0_profile.profilePicture
                      : man
                  }`,
                  alt: "",
                }}
              />
              <Media body>
                <span>
                  {auth0_profile.firstName + " " + auth0_profile.lastName}
                </span>
              </Media>
            </Media>
            <UL
              attrUL={{
                className: `simple-list profile-dropdown onhover-show-div p-0`,
              }}
            >
              <LI attrLI={{ className: "p-0" }}>
                <Link to={`${process.env.PUBLIC_URL}/profile/me`}>
                  <div className="p-10 d-none">
                    <i>
                      <User />
                    </i>
                    <span>{"My Profile"} </span>
                  </div>
                </Link>
              </LI>

              <LI attrLI={{ onClick: Logout, className: "p-0" }}>
                <Link to={`${process.env.PUBLIC_URL}/sign-in`}>
                  <div className="p-10" style={{ background: "#f1f0f0" }}>
                    <LogIn />
                    <span>{"Log Out"}</span>
                  </div>
                </Link>
              </LI>
            </UL>
          </LI>
        </UL>
      </div>
    </Fragment>
  );
};
export default HeaderContain;
