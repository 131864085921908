import React from "react";
import { Card } from "reactstrap";
import ProfilePicture from "./ProfilePicture";

export default function About({ profileInfo, userId, userName }) {
  return (
    <div>
      <Card style={{ minHeight: "100vh", padding: "11px" }}>
        <div>
          <h3
            style={{
              color: "#2a6a7a",
            }}
          >
            About Me
          </h3>
        </div>
        <div>
          <ProfilePicture
            profileInfo={profileInfo}
            userId={userId}
            userName={userName}
          />
        </div>
      </Card>
    </div>
  );
}
