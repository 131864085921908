import { toast } from "react-toastify";

export function toastNotify(msg, type) {
  if (type === "success") {
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
  } else if (type === "warn") {
    toast.warn(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
  } else if (type === "danger") {
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
  }
}
