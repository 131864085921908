import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/logo/logo-icon.png";
import { Image } from "../../../shared/AbstractElements";

const SidebarLogo = () => {
  return (
    <div className="logo-icon-wrapper">
      <Link to={`${process.env.PUBLIC_URL}/home`}>
        <Image
          attrImage={{
            className: "img-fluid for-dark",
            src: `${logo}`,
            alt: "1234",
          }}
        />
      </Link>
    </div>
  );
};

export default SidebarLogo;
