import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Form, FormGroup, FormText, Input, Label } from 'reactstrap';
import { AddSubjectApi, UpdateSubjectApi } from '../../api';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

const AddUpdateSubjects = () => {
    const [subjectName, setSubjectName] = useState('');
    const [subjectCategory, setSubjectCategory] = useState('');
    const [subjectSubCategory, setSubjectSubCategory] = useState('');
    const navigate = useNavigate();
    const { id } = useParams();

    // subject add section 
    // ----------------------
    const handleAddSubject = async (e) => {
        e.preventDefault();
        if (!subjectName || !subjectCategory) {
            toast.warn('Please provide required informations');
        }

        const subject = {
            name: subjectName,
            tag: subjectCategory + (subjectSubCategory ? `/${subjectSubCategory}` : '')
        }

        const userData = JSON?.parse(localStorage.getItem("adminData"));
        const localStorageToken = userData?.token;
        if (!localStorageToken) {
            toast.warn("Invalid Token!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        const confirm = window.confirm('Do you want to add this Subject?');
        if (!confirm) {
            return;
        }
        try {
            const res = await axios.post(AddSubjectApi, { subject: subject }, {
                headers: {
                    authorization: `Bearer ${localStorageToken}`,
                },
            });
            // console.log("data", res.data);
            if (res?.data && res?.data?.id) {
                toast.success('Subject added successfully');
                navigate('/subjects')
            }
        } catch (error) {
            toast.error('There was an error saving Subject');
            console.log(error);
        }
    }

    // subject update section 
    // ----------------------------

    useEffect(() => {
        if (id) {
            const selectedSubject = JSON.parse(localStorage.getItem('updateSubject'));
            if (selectedSubject) {
                setSubjectName(selectedSubject?.name);
                const tagSplitter = selectedSubject?.tag?.split('/');
                setSubjectCategory(tagSplitter[0] ?? '');
                setSubjectSubCategory(tagSplitter[1] ?? '');
            }
        }
    }, [id]);

    const handleUpdateSubject = async (e) => {
        e.preventDefault();
        if (!subjectName || !subjectCategory) {
            toast.warn('Please provide required informations');
        }

        const subject = {
            name: subjectName,
            tag: subjectCategory + (subjectSubCategory ? `/${subjectSubCategory}` : '')
        }

        const userData = JSON?.parse(localStorage.getItem("adminData"));
        const localStorageToken = userData?.token;
        if (!localStorageToken) {
            toast.warn("Invalid Token!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        const confirm = window.confirm('Do you want to update this Subject?');
        if (!confirm) {
            return;
        }
        try {
            const res = await axios.put(UpdateSubjectApi + id, { subject: subject }, {
                headers: {
                    authorization: `Bearer ${localStorageToken}`,
                },
            });
            // console.log("data", res.data);
            if (res?.data && res?.data?.subject?.id) {
                toast.success('Subject Updated successfully');
                navigate('/subjects')
            }
        } catch (error) {
            toast.error('There was an error updating Subject');
            console.log(error);
        }
    };

    return (
        <div>
            <div>
                <h3>Add New Subject</h3>
            </div>

            <section className='w-75 ms-5 mt-4'>
                <Form>
                    <FormGroup>
                        <Label className='fs-5'>
                            Subject Name
                        </Label>
                        <Input
                            id='subject-name'
                            name="subject-name"
                            placeholder="name..."
                            type="text"
                            required
                            value={subjectName}
                            onChange={(e) => setSubjectName(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label className='fs-5'>
                            Subject Category
                        </Label>
                        <Input
                            id='subject-category'
                            name="subject-category"
                            placeholder="category..."
                            type="text"
                            required
                            value={subjectCategory}
                            onChange={(e) => setSubjectCategory(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label className='fs-5'>
                            Subject Sub Category
                        </Label>
                        <Input
                            id='subject-sub-category'
                            name="subject-sub-category"
                            placeholder="sub category..."
                            type="text"
                            value={subjectSubCategory}
                            onChange={(e) => setSubjectSubCategory(e.target.value)}
                        />
                    </FormGroup>

                    <Button className='fw-semibold' onClick={id ? handleUpdateSubject : handleAddSubject} color='primary'>
                        {id ? 'Update' : 'Add'} Subject
                    </Button>
                </Form>
            </section>
        </div>
    );
};

export default AddUpdateSubjects;