import React, { Fragment } from "react";
import { Col, Container, Row } from "reactstrap";
import "./Footer.css";

const Footer = () => {
  return (
    <Fragment>
      <footer>
        <Container fluid={true}>
          <Row>
            <Col md="12" className="footer-copyright text-center">
              <p className="mb-0">Copyright 2022 © siara solutions pty ltd </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </Fragment>
  );
};
export default Footer;
