import axios from "axios";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import {
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Col,
  Row,
  Card,
} from "reactstrap";
import { ExpiredVoucherListGetApi } from "../../api";
import { FaBars, FaEdit } from "react-icons/fa"; // Importing icons
import "./voucherCard.css";
import VoucherEditForm from "./VoucherEditForm";
import VoucherDetails from "./VoucherDetails";
import CommonPagination from "../common/Pagination/CommonPagination";

const ExpiredVoucher = () => {
  const [ActiveVoucherList, setActiveVoucherList] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("ascending");
  const [selectedVoucher, setSelectedVoucher] = useState(null);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;

  const toggleDetailsModal = () => setDetailsModalOpen(!detailsModalOpen);

  const userData = JSON.parse(localStorage.getItem("adminData"));
  const localStorageToken = userData.token;

  const fetchActiveVoucherList = async () => {
    const res = await axios.get(ExpiredVoucherListGetApi, {
      headers: {
        authorization: `Bearer ${localStorageToken}`,
      },
    });
    if (res.data) {
      setActiveVoucherList(res.data);
    }
  };

  useEffect(() => {
    fetchActiveVoucherList();
  }, []);

  const sortOptions = [
    { value: "discountAmount", label: "Discount Amount" },
    { value: "createdAt", label: "Created At" },
    { value: "expiresAt", label: "Expires At" },
  ];

  const handleSortByChange = (event) => {
    setSortBy(event.target.value);
    setSortOrder("ascending");
  };

  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value);
  };

  const handleDetailsClick = (voucher) => {
    setSelectedVoucher(voucher);
    toggleDetailsModal();
  };

  const sortedVoucherList = () => {
    if (!sortBy) {
      return ActiveVoucherList;
    }

    return ActiveVoucherList.slice().sort((a, b) => {
      if (sortBy === "expiresAt" || sortBy === "createdAt") {
        const dateA = new Date(a[sortBy]);
        const dateB = new Date(b[sortBy]);

        if (sortOrder === "ascending") {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      } else if (sortBy === "discountAmount") {
        const numA = parseFloat(a[sortBy]);
        const numB = parseFloat(b[sortBy]);

        if (sortOrder === "ascending") {
          return numA - numB;
        } else {
          return numB - numA;
        }
      }
    });
  };

  const [editingVoucherId, setEditingVoucherId] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  // ... (existing code)

  const handleEditClick = (voucherId) => {
    // Set the currently editing voucher ID
    setEditingVoucherId(voucherId);
    setIsEditModalOpen(true);
  };

  const handleCancelEdit = () => {
    // Clear the currently editing voucher ID to exit edit mode
    setEditingVoucherId(null);
    setIsEditModalOpen(false);
  };

  const sortedList = sortedVoucherList();

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const paginationData = sortedList?.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (
    let i = Math.max(1, currentPage - 2);
    i <=
    Math.min(Math.ceil(sortedList?.length / itemsPerPage), currentPage + 2);
    i++
  ) {
    pageNumbers.push(i);
  }

  return (
    <Card style={{ minHeight: "100vh", padding: "11px" }}>
      <div className="d-flex justify-content-between align-items-center">
        <h3 style={{ color: "#ff0000" }}>Expired Voucher</h3>

        <div>
          <label>Sort by:</label>
          <select onChange={handleSortByChange} value={sortBy}>
            <option value="">-- Select --</option>
            {sortOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {sortBy && (
            <div>
              <label>Sort Order:</label>
              <select onChange={handleSortOrderChange} value={sortOrder}>
                <option value="ascending">Ascending</option>
                <option value="descending">Descending</option>
              </select>
            </div>
          )}
        </div>
      </div>

      <div>
        {sortedList?.length} {sortedList?.length > 1 ? "Vouchers" : "Voucher"}{" "}
        Found
      </div>
      <Table
        style={{
          background: "#ffffff",
          boxShadow: "0px 0px white",
          borderRadius: "0px",
          border: "0px",
        }}
      >
        <thead>
          <tr style={{ borderBottom: "3px solid black" }}>
            <th>SL</th>
            <th>Voucher Title</th>
            <th>Created By</th>
            <th>Discount Type</th>
            <th>Details</th>
            <th>Edit</th>
          </tr>
        </thead>
        {sortedList && sortedList?.length > 0 ? (
          <tbody>
            {paginationData &&
              paginationData?.map((voucher, index) => (
                <tr
                  key={voucher._id}
                  style={{ borderBottom: "2px solid lightgrey" }}
                >
                  <td>{index + 1}</td>
                  <td className="fw-normal">{voucher.code}</td>
                  <td className="fw-normal">{voucher.createdBy}</td>
                  <td className="fw-normal">
                    {voucher.discountType === "fixed"
                      ? "Fixed"
                      : voucher.discountType === "percentage"
                      ? "Percentage"
                      : voucher.discountType}
                  </td>
                  <td>
                    <FaBars
                      style={{ color: "#ff7600", cursor: "pointer" }}
                      className="action-icon"
                      onClick={() => handleDetailsClick(voucher)}
                    />
                  </td>
                  <td>
                    <FaEdit
                      style={{ color: "#6362e7", cursor: "pointer" }}
                      className="action-icon"
                      onClick={() => handleEditClick(voucher._id)}
                    />
                    {/* Show the edit modal when the corresponding voucher is in edit mode */}
                    {editingVoucherId === voucher._id && isEditModalOpen && (
                      <Modal
                        isOpen={isEditModalOpen}
                        toggle={handleCancelEdit}
                        size="lg"
                      >
                        {/* <div style={{ width: "fit-content" }}> */}
                        <ModalHeader toggle={handleCancelEdit}>
                          Edit Voucher
                        </ModalHeader>
                        <ModalBody className="wider-modal-content">
                          {/* Render the VoucherEditForm inside the edit modal */}
                          <VoucherEditForm voucher={voucher} />
                        </ModalBody>
                        <ModalFooter>
                          <Button color="secondary" onClick={handleCancelEdit}>
                            Cancel
                          </Button>
                        </ModalFooter>
                        {/* </div> */}
                      </Modal>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan="6" style={{ textAlign: "center" }}>
                <h3 style={{ color: "#ff8819", marginTop: "50px" }}>
                  No expired voucher to show
                </h3>
              </td>
            </tr>
          </tbody>
        )}
      </Table>

      <VoucherDetails
        isOpen={detailsModalOpen}
        toggleModal={toggleDetailsModal}
        selectedVoucher={selectedVoucher}
      />
      <CommonPagination
        currentPage={currentPage}
        totalPages={Math.ceil(sortedList?.length / itemsPerPage)}
        paginate={setCurrentPage}
      />
    </Card>
  );
};

export default ExpiredVoucher;
