import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Button,
  FormGroup,
} from "reactstrap";

import axios from "axios";
import { toast } from "react-toastify";

import {
  EducationInfoSaveApi,
  UpdateMemberEducationInfo,
  DocumentRootURL,
} from "../../../../api";
import { useCourseAndDegreeList } from "../../../../hooks/courseAndDegreeList/useCourseAndDegreeList";
import { useMemberDetailsContext } from "../../../../shared/_helper/MemberDetails/MemberDetailsProvider";
import { saveSingleAttachmentToCloudinary } from "../../../common/cloudinaryImageAttachments/CommonPhotoAttachmentFn";

const EducationInfoEditModal = ({
  modalOpen,
  setModalOpen,
  selectedEditItem,
  refetch,
  memberDateOfBirth,
  userId,
}) => {
  // const [courseList, setCourseList] = useState([]);
  // const [degreeList, setDegreeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const ref = useRef();
  const { data } = useCourseAndDegreeList();
  const { courseList, degreeList } = useMemberDetailsContext();

  //   const {
  //     getMemberInformation,
  //     courseList: courseList1,
  //     degreeList: degreeList1,
  //     getDegreeAndcourseList,
  //     dateOfBirth,
  //     setIsVisible,
  //   } = useContext(MemberInformationContext);

  //   const { user } = useSelector((state) => state.auth) || {};
  //   let { data: memberInfo, refetch } = useGetMemberInfoByIdQuery(user);

  // post request
  const [degreeName, setDegreeName] = useState("");
  const [degree, setDegree] = useState({});
  const [courseName, setCourseName] = useState("");
  const [course, setCourse] = useState({});
  const [institute, setInstitute] = useState("");
  const [grade, setGrade] = useState("");
  const [passingYear, setPassingYear] = useState();
  const [attachment, setAttachment] = useState([]);
  const [attachmentURL, setAttachmentURL] = useState(" ");
  const [oldAttachmentInfo, setOldAttachmentInfo] = useState("");
  const [newAttachmentInfo, setNewAttachmentInfo] = useState("");
  const [attachmentCludinaryRes, setAttachmentCloudinaryRes] = useState();

  const [passingYearList, setPassingYears] = useState([]);
  const [inProgress, setInProgress] = useState(false);
  const toggle = () => setModalOpen(!modalOpen);

  // const [selectedDegree, setSelectedDegree] = useState({});
  // const [selectedCourse, setSelectedCourse] = useState({});
  const [modifiedDegreeList, setModifiedDegreeList] = useState([]);
  const [modifiedCourseList, setModifiedCourseList] = useState([]);

  //validate result percentage
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isValidGrade, setIsValidGrade] = useState(true);
  const [gradeErrorMessage, setGradeErrorMessage] = useState(
    " min: 0% and max:100%"
  );

  const fnIsValidGrade = (Number) => {
    if (Number >= 0 && Number <= 100) return true;
    else return false;
  };

  useEffect(() => {
    if (!fnIsValidGrade(grade)) {
      setIsValidGrade(false);
      setShowErrorMessage(true);
    } else {
      setIsValidGrade(true);
      setShowErrorMessage(false);
    }
  }, [grade]);

  //   useEffect(() => {
  //     if (degreeList?.length <= 0 || courseList?.length <= 0) {
  //       getDegreeAndcourseList();
  //     }

  //     if (props.singleEduData.inProgress) {
  //       fnPassingYearList(6, "next");
  //     } else {
  //       fnPassingYearList(
  //         parseInt(
  //           new Date().getFullYear() - new Date(dateOfBirth).getFullYear() + 1
  //         ),
  //         "prev"
  //       );
  //     }

  //   }, []);

  // console.log(
  //   "convertAttachmentIntoCloudinary",
  //   convertAttachmentIntoCloudinary
  // );

  useEffect(() => {
    if (degreeList?.length > 0 && courseList?.length > 0) {
      fnModifiedDegreeList(degreeList);
      fnModifiedCourseList(courseList);
    }
  }, [degreeList, courseList]);

  useEffect(() => {
    const degreeObj = {
      label: selectedEditItem?.degree?.label,
      value: selectedEditItem?.degree?.value,
    };
    setDegree(degreeObj);
    setDegreeName(degreeObj?.label);
    const courseObj = {
      label: selectedEditItem?.course?.label,
      value: selectedEditItem?.course?.value,
    };
    setCourse(courseObj);
    setCourseName(courseObj.label);
    setInstitute(selectedEditItem.institute);
    setInProgress(selectedEditItem.isRunning);
    setGrade(selectedEditItem.marks);
    setAttachment(selectedEditItem.attachments);
    setNewAttachmentInfo(
      selectedEditItem.attachments?.attachmentInfo?.attachmentInfo?.secure_url
    );
    setOldAttachmentInfo(selectedEditItem.attachments);

    // if (
    //   selectedEditItem.attachments?.attachmentInfo?.attachmentInfo
    //     ?.secure_url &&
    //   selectedEditItem.attachments?.attachmentInfo?.attachmentInfo?.secure_url
    //     ?.length > 0
    // ) {
    //   setNewAttachmentInfo(
    //     selectedEditItem.attachments?.attachmentInfo?.attachmentInfo?.secure_url
    //   );
    // } else if (
    //   selectedEditItem.attachments?.name &&
    //   selectedEditItem.attachments?.name?.length > 0
    // ) {
    //   setOldAttachmentInfo(selectedEditItem.attachments);
    // }
    // setAttachment(selectedEditItem.attachments);
    //   setAttachment(selectedEditItem.attachments);

    if (selectedEditItem.isRunning && selectedEditItem.isRunning !== "false") {
      fnPassingYearList(6, "next");
    } else {
      fnPassingYearList(
        parseInt(
          new Date().getFullYear() -
            new Date(memberDateOfBirth).getFullYear() +
            1
        ),
        "prev"
      );
    }
  }, [selectedEditItem]);

  const fnModifiedDegreeList = (result) => {
    result = result.slice().sort((a, b) => a.name.localeCompare(b.name));
    var degreeList2 = [];
    if (result) {
      result.map((item, index) => {
        degreeList2.push({
          value: item._id,
          label: item.name,
          isDisabled: false,
        });
      });
    }
    setModifiedDegreeList(degreeList2);

    // setSelectedDegree(
    //   degreeList2?.filter(
    //     ({ value }) => value === selectedEditItem.degree.label
    //   )[0]
    // );
  };
  const fnModifiedCourseList = (result) => {
    result = result.slice().sort((a, b) => a.name.localeCompare(b.name));
    var courseList2 = [];
    if (result) {
      result.map((item, index) => {
        courseList2.push({
          value: item._id,
          label: item.name,
          isDisabled: false,
        });
      });
    }
    setModifiedCourseList(courseList2);

    // setSelectedCourse(
    //   courseList2?.filter(
    //     ({ value }) => value === selectedEditItem.course.label
    //   )[0]
    // );
  };

  const fnPassingYearList = (count, type) => {
    const year = new Date().getFullYear();
    let years = [];
    if (type === "next") {
      years = Array.from(new Array(count), (val, index) => year + index);
    } else {
      years = Array.from(new Array(count), (val, index) => year - index);
    }

    var yearList = [];
    if (years) {
      years?.map((item, index) => {
        yearList.push({
          value: item,
          label: item,
          isDisabled: false,
        });
      });
    }

    setPassingYears(yearList);
    setPassingYear(
      yearList?.filter(({ value }) => value == selectedEditItem.passingYear)[0]
    );
  };

  const isValidFileUploaded = (file) => {
    const validExtensions = ["png", "jpeg", "jpg", "pdf"];
    const fileExtension = file.type.split("/")[1];
    return validExtensions.includes(fileExtension);
  };
  const onFilesChange = (e) => {
    if (e.target.files.length < 1) {
      return;
    }
    if (isValidFileUploaded(e.target.files[0])) {
      uploadToCloudinary(e.target.files[0]);
      setAttachment(e.target.files[0]);
      setAttachmentURL(window.URL.createObjectURL(e.target.files[0]));
    } else {
      //   toastNotifiy("File not valid. [Only pdf,png,jpeg,jpg accpeted]", "warn");
      e.target.value = null;
      ref.current.value = "";
    }
  };

  const handleAttachmentConvertIntoCloudinary = async (url, fileName) => {
    // Determine MIME type based on file extension
    const fileType = fileName.split(".").pop().toLowerCase();
    let mimeType;
    if (fileType === "pdf") {
      mimeType = "application/pdf";
    } else if (["png", "jpeg", "jpg"].includes(fileType)) {
      mimeType = "image/jpeg";
    } else {
      // Handle unsupported file types
      throw new Error("Unsupported file type");
    }

    const response = await fetch(url);
    const blob = await response.blob();

    const file = new File([blob], fileName, { type: mimeType });

    if (attachmentURL !== "") {
      uploadToCloudinary(file);
    }

    return file;
  };

  const uploadToCloudinary = async (file) => {
    try {
      if (userId && file) {
        const { attachmentUrl } = await saveImageToCloudinary(file);

        if (attachmentURL != "") {
          handleUpdateEduInfo(attachmentUrl);
        } else {
          setAttachmentCloudinaryRes(attachmentUrl);
        }
      }
    } catch (error) {
      alert("Select image again");
    }
  };

  const saveImageToCloudinary = async (file) => {
    return saveSingleAttachmentToCloudinary(file, `attachment/${userId}`);
  };

  const fileName = oldAttachmentInfo?.name?.substring(
    oldAttachmentInfo?.name?.indexOf("_") + 1
  );

  const validatedField = () => {
    if (
      !degree?.label ||
      !course?.label ||
      !institute ||
      !grade ||
      !passingYear
    ) {
      //   toastMessage("Fillup Required Field!", "warn");
      return false;
    } else if (!isValidGrade) {
      //   toastMessage("Result validation failed!", "warn");
      return false;
    } else if (!isNaN(institute)) {
      //   toastMessage("Enter Valid Institute Name!", "warn");
      return false;
    } else {
      return true;
    }
  };

  const handleUpdateEduInfo = async (attachmentUrl) => {
    // const attachmentUrl = {
    //   imageInfo: attachmentUrl?.imageInfo,
    //   storageProvider: attachmentUrl?.storageProvider,
    // };

    if (validatedField()) {
      if (window.confirm("Are you sure to update this information?")) {
        setLoading(true);

        const selectedCourseObject = {
          label: course.label,
          value: course.value,
        };
        const selectedDegreeObject = {
          label: degree.label,
          value: degree.value,
        };

        // let attachmentUrl, uploadedUrl;

        // if (attachment) {
        //   const result = await saveSingleAttachmentToCloudinary(
        //     attachment,
        //     `attachment/${_tuteair?._id}`
        //   );
        //   attachmentUrl = result.attachmentUrl;
        //   uploadedUrl = result.uploadedUrl;

        // }

        const value = {
          course: selectedCourseObject,
          degree: selectedDegreeObject,
          institute,
          marks: grade,
          passingYear: passingYear.value,
          // attachment: attachmentUrl,
          attachment: attachmentUrl,
          isRunning: false,
        };

        const userData = JSON?.parse(localStorage.getItem("adminData"));

        const localStorageToken = userData?.token;
        if (!localStorageToken) {
          toast.error("Invalid token");
          return;
        }

        const res = await axios.put(
          `${UpdateMemberEducationInfo}/${userId}/${selectedEditItem.id}`,
          value,
          {
            headers: {
              authorization: `Bearer ${localStorageToken}`,
            },
          }
        );

        if (res.status === 201) {
          if (res.data) {
            refetch();
            toast.success("Education Info Updated successully", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
            toggle();
          } else {
            toast.warn("Failed to add", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
          }
          setLoading(false);
        } else {
          setLoading(false);
          toast.warn("something went wrong", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
      }
    }
  };

  //   if (window.confirm("Are you sure to delete this file?")) {
  //     setAttachment([]);
  //     setDocumentID("");
  //     setAttachmentOriginalName("");
  //     ref.current.value = "";
  //     document.getElementById("fileControl").value = "";
  //   }
  // }

  //   const handleAddEduInfo = async (e) => {
  //     e.preventDefault();
  //     if (validatedField()) {
  //       if (window.confirm("Are you sure to save this information?")) {
  //         setLoading(true);

  //         const formData = new FormData();

  //         const selectedCourseObject = {
  //           label: selectedCourse.label,
  //           value: selectedCourse.value,
  //         };
  //         const selectedDegreeObject = {
  //           label: selectedDegree.label,
  //           value: selectedDegree.value,
  //         };

  //         const _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
  //         let attachmentUrl, uploadedUrl;

  //         if (attachment) {
  //           const result = await saveSingleAttachmentToCloudinary(
  //             attachment,
  //             `attachment/${_tuteair?._id}`
  //           );
  //           attachmentUrl = result.attachmentUrl;
  //           uploadedUrl = result.uploadedUrl;
  //         }

  //         e.preventDefault();

  //         const value = {
  //           course: selectedCourseObject,
  //           degree: selectedDegreeObject,
  //           institute,
  //           marks: grade,
  //           passingYear: passingYear.value,
  //           attachment: attachmentUrl,
  //           isRunning: inProgress,
  //         };

  //         // formData.append("course", JSON.stringify(selectedCourseObject));
  //         // formData.append("degree", JSON.stringify(selectedDegreeObject));
  //         // formData.append("institute", institute);
  //         // formData.append("marks", grade);
  //         // formData.append("passingYear", passingYear.value);
  //         // formData.append("document", attachment);
  //         // formData.append("documentID", "");
  //         // formData.append("isRunning", inProgress);

  //         const res = await axios.post(EducationInfoSaveApi, value, {
  //           headers: {
  //             Authorization: authHeader(),
  //           },
  //         });

  //         if (res.status === 201) {
  //           if (res.data) {
  //             refetch();
  //             toast.success("Education Info Added successully", {
  //               position: toast.POSITION.TOP_CENTER,
  //               autoClose: 3000,
  //             });
  //             setIsVisible(true);
  //             props.toggler();
  //           } else {
  //             toast.warn("Failed to add", {
  //               position: toast.POSITION.TOP_CENTER,
  //               autoClose: 3000,
  //             });
  //           }
  //           setLoading(false);
  //         } else {
  //           setLoading(false);
  //           toast.warn("something went wrong", {
  //             position: toast.POSITION.TOP_CENTER,
  //             autoClose: 3000,
  //           });
  //         }
  //       }
  //     }
  //   };

  const checkboxHandler = async (isChk) => {
    setPassingYear("");
    setInProgress(isChk);
    if (isChk) {
      fnPassingYearList(6, "next");
    } else {
      fnPassingYearList(
        parseInt(
          new Date().getFullYear() -
            new Date(memberDateOfBirth).getFullYear() +
            1
        ),
        "prev"
      );
    }
  };

  const haneldChangeDegree = (event) => {
    const value = event.target.value;

    const selectedDegree = modifiedDegreeList?.find(
      (item) => item.label === value
    );
    setDegree(selectedDegree);
    setDegreeName(selectedDegree.label);
  };

  const handleChangeCourse = (event) => {
    const value = event.target.value;
    const selectedCourse = modifiedCourseList?.find(
      (item) => item.label === value
    );
    setCourse(selectedCourse);
    setCourseName(selectedCourse.label);
  };

  return (
    <Fragment>
      <Modal isOpen={modalOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          <h3
            style={{
              color: "#2a6a7a",
            }}
          >
            Update Education Info
          </h3>
        </ModalHeader>
        <ModalBody>
          <Col sm="12" xl="12 box-col-12">
            <Row>
              <Col sm="12" xl="12">
                <div className="row mb-2">
                  <Label
                    className="col-sm-2 col-form-label asterisk"
                    htmlFor="inputEmail3"
                  >
                    Degree
                  </Label>
                  <Col sm="10">
                    <FormGroup className="w-full md:w-80">
                      {/* <Label for="level">Level</Label> */}
                      <Input
                        type="select"
                        name="degree"
                        id="degree"
                        value={degreeName}
                        onChange={haneldChangeDegree}
                      >
                        {modifiedDegreeList.map((name) => (
                          <option key={name.value} value={name.label}>
                            {name.label}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                    {/* <Select
                          options={degreeList}
                          placeholder="Select Degree"
                          className="js-example-basic-single col-sm-12 darkMode"
                          selecte
                          onChange={(item) => setSelectedDegree(item)}
                          value={selectedDegree}
                          disabled={degreeList?.length === 0}
                        /> */}
                  </Col>
                  {/*<Col sm="2">
                        <Btn
                          attrBtn={{
                            color: "success",
                          }}
                        >
                          <i className="fa fa-plus"></i>
                        </Btn>
                        </Col>*/}
                </div>
                <div className="row mb-2">
                  <Label
                    className="col-sm-2 col-form-label asterisk"
                    htmlFor="inputEmail3"
                  >
                    Course
                  </Label>
                  <Col sm="10">
                    <FormGroup className="w-full md:w-80">
                      {/* <Label for="level">Level</Label> */}
                      <Input
                        type="select"
                        name="course"
                        id="course"
                        value={courseName}
                        onChange={handleChangeCourse}
                      >
                        {modifiedCourseList.map((name) => (
                          <option key={name.value} value={name.label}>
                            {name.label}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                    {/* <Select
                          options={courseList}
                          placeholder="Select Course"
                          className="js-example-basic-single col-sm-12 darkMode"
                          onChange={(item) => setSelectedCourse(item)}
                          value={selectedCourse}
                          disabled={courseList?.length === 0}
                        /> */}
                  </Col>{" "}
                  {/*<Col sm="2">
                        <Btn
                          attrBtn={{
                            color: "success",
                          }}
                        >
                          <i className="fa fa-plus"></i>
                        </Btn>
                      </Col>*/}
                </div>
                <div className="row mb-4">
                  <Label
                    className="col-sm-2 col-form-label asterisk"
                    htmlFor="inputEmail3"
                  >
                    Institute
                  </Label>
                  <Col sm="10">
                    <Input
                      type="text"
                      name=""
                      placeholder="Enter Your Institute"
                      className="form-control"
                      value={institute}
                      onChange={(e) => setInstitute(e.target.value)}
                    />
                    {institute && !isNaN(institute) && (
                      <div className="text-danger f-12">
                        Enter valid institute name
                      </div>
                    )}
                    {/* <Input
                          type="text"
                          name="select"
                          className="form-control"
                          defaultValue={institute}
                          onChange={(e) => setInstitute(e.target.value)}
                        >
                          <option value="" disabled>
                            Select Institute
                          </option>
                          {instituteList?.map((item, index) => (
                            <option key={index} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                        </Input> */}
                  </Col>{" "}
                  {/*<Col sm="2">
                        <Btn
                          className="btn-sm"
                          attrBtn={{
                            color: "success",
                          }}
                        >
                          <i className="fa fa-plus"></i>
                        </Btn>
                      </Col>*/}
                </div>
                <div className="row mb-2">
                  <Label
                    className="col-sm-2 col-form-label asterisk"
                    htmlFor="inputEmail3"
                  >
                    Result
                  </Label>
                  <Col sm="4">
                    <Input
                      className="form-control"
                      type="text"
                      pattern="/[0-9]+/"
                      placeholder="Enter score in percentage"
                      value={grade}
                      onChange={(e) =>
                        setGrade(
                          e.target.value
                            .replace(/[^0-9.]/g, "")
                            .replace(/(\..*?)\..*/g, "$1")
                        )
                      }
                      //onChange={handleGradeChange}
                    />
                    {showErrorMessage && !isValidGrade ? (
                      <div className="text-danger f-12">
                        {gradeErrorMessage}
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Label
                    className="col-sm-2 col-form-label asterisk"
                    htmlFor="inputEmail3"
                  >
                    Completion Year
                  </Label>
                  <Col sm="2">
                    <FormGroup className="w-full md:w-80">
                      {/* <Label for="level">Level</Label> */}
                      <Input
                        type="select"
                        name="passingYear"
                        id="passingYear"
                        value={passingYear?.label}
                        // onChange={handleChangeLevel}
                      >
                        {passingYearList.map((name) => (
                          <option key={name.value} value={name.label}>
                            {name.label}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                    {/* <Select
                          options={passingYearList}
                          placeholder="Select Year"
                          className="js-example-basic-single col-sm-12 darkMode"
                          onChange={(item) => setPassingYear(item)}
                          value={passingYear}
                        /> */}
                  </Col>
                  <Col sm="2" className="d-flex flex-column">
                    <div className="row" style={{ placeItems: "center" }}>
                      <Col xs="2" sm="2">
                        <Input
                          id=""
                          type="checkbox"
                          checked={inProgress === true}
                          onChange={(event) => {
                            checkboxHandler(event.target.checked);
                          }}
                        />
                      </Col>
                      <Col xs="10" sm="10">
                        <Label
                          className="col-form-label "
                          htmlFor="inputEmail3"
                        >
                          In Progress
                        </Label>
                      </Col>
                    </div>
                  </Col>
                </div>
                <div className="row mb-2">
                  <Label
                    className="col-sm-2 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Attachments
                  </Label>
                  <Col sm="7">
                    <Input
                      className="form-control"
                      type="file"
                      id="fileControl"
                      onChange={onFilesChange}
                      ref={ref}
                      title="Choose a Attachment "
                    />
                  </Col>
                  <Col
                    sm="3"
                    className={
                      attachment?.length > 0 || attachment?.size > 0
                        ? ""
                        : "d-none"
                    }
                  >
                    {/* <Button
                      attrBtn={{
                        className: "btn",
                        color: "danger",
                        type: "button",
                        onClick: (e) => deleteFile(e),
                      }}
                    >
                      Delete
                    </Button> */}
                  </Col>
                </div>
              </Col>
              <Col sm="12" xl="12">
                <div className="row mb-2">
                  <Label
                    className="col-sm-2 col-form-label"
                    htmlFor="inputEmail3"
                  ></Label>
                  <Col sm="10">
                    <u>
                      <a
                        className=""
                        href={
                          newAttachmentInfo ||
                          DocumentRootURL + oldAttachmentInfo?.id
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        {newAttachmentInfo
                          ? newAttachmentInfo?.split("/").pop()
                          : oldAttachmentInfo?.name?.substring(
                              oldAttachmentInfo?.name?.indexOf("_") + 1
                            )}
                      </a>
                    </u>
                  </Col>
                </div>
              </Col>
            </Row>
          </Col>
        </ModalBody>
        <ModalFooter>
          {newAttachmentInfo || newAttachmentInfo == undefined ? (
            <Button
              color="primary"
              variant="contained"
              className="mt-6"
              onClick={() =>
                // handleUpdateEduInfo(attachmentCludinaryRes || attachment)
                handleUpdateEduInfo(attachmentCludinaryRes)
              }
            >
              Update
            </Button>
          ) : (
            <Button
              color="primary"
              onClick={() =>
                handleAttachmentConvertIntoCloudinary(
                  DocumentRootURL + oldAttachmentInfo?.id,
                  fileName
                )
              }
            >
              Save To Cloudinary
            </Button>
          )}

          {/* <Button
            style={{ fontFamily: "sans-serif" }}
            color="secondary"
            onClick={toggle}
          >
            Cancel
          </Button>
          <Button
            style={{ fontFamily: "sans-serif" }}
            color="primary"
            onClick={(e) => handleUpdateEduInfo(e)}
          >
            Update
          </Button> */}
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default EducationInfoEditModal;
